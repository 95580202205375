// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'

const metodos = {
  namespaced: true,
  state: () => ({
    items: [
      { // LISTO - whatsapp-sac
        activo: true,
        id: 'whatsapp-sac',
        de: 'whatsapp',
        para: 'sac',
        desc: 'Recibe <b>ayuda directa </b> a través de Whatsapp.',
        instr: '',
        msgOk: 'Tu solicitud fue enviada.<br><b>Gracias por apoyarnos!</b>',
        canales: { efectivo: 1, whatsapp: 1 },
        mundo: true,
        paises: {},
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Solicitar ayuda por Whatsapp',
            title: 'Solicitar ayuda por Whatsapp',
            icon: 'fa-brands fa-whatsapp',
            btnTxt: 'Solicitar ayuda',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos, según la ley 222 de Habeas Data que puedes consultar en...',
            infoType: 'info',
            isLast: true,
            storeAction: 'sac/tickets/clienteSolicitaAyudaPorWhatsapp',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true
                },
                isWapp: {
                  show: false,
                  required: false,
                  value: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 15,
                  number: true
                },
                phonePrefix: {
                  required: true
                }
              }
            }
          }
        ]
      },
      { // nequi-nequi
        activo: false,
        id: 'nequi-nequi',
        de: 'nequi',
        para: 'nequi',
        desc: 'Usa <b>tu Nequi</b> para enviarnos una donación.',
        instr: '',
        msgOk: '',
        canales: { nequi: 1, wompi: 1 },
        mundo: false,
        paises: { co: true },
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Datos de<br>contacto',
            title: 'Datos de contacto',
            icon: '',
            btnTxt: '',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos, según la ley 222 de Habeas Data que puedes consultar en...',
            infoType: 'info',
            isLast: false,
            storeAction: '',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 13,
                  number: true
                },
                phonePrefix: {
                  required: true
                }
              }
            }
          },
          {
            id: 2,
            type: 'component', // component || store.action
            optional: true,
            label: 'Mensaje',
            title: 'Mensaje',
            icon: '',
            btnTxt: '',
            info: '',
            infoType: 'info',
            isLast: false,
            storeAction: '',
            component: 'step-form-mensaje',
            params: {
              fields: {
                message: {
                  required: false,
                  max: 240
                }
              },
              messageSource: '' // nombre de un getter
            }
          },
          {
            id: 3,
            type: 'component', // component || store.action
            optional: false,
            label: 'Enviar',
            title: 'Enviar',
            icon: '',
            btnTxt: 'Enviar',
            info: '',
            infoType: 'info',
            isLast: true,
            storeAction: '',
            component: 'step-whatsapp-sac',
            params: {}
          }
        ]
      },
      { // LISTO - efectivo-bancolombia
        activo: true,
        id: 'efectivo-bancolombia',
        de: 'efectivo',
        para: 'bancolombia',
        desc: '<b>Consigna o transfiere </b> a Bancolombia.',
        instr: 'consignar o transferir a su cuenta en Bancolombia.',
        msgOk: 'Nos contactaremos contigo a través de Whatsapp para ponernos a tu disposición por si necesitas algo más. <b>Gracias por tu generosidad.</b>',
        canales: { efectivo: 1, bancolombia: 1 },
        mundo: false,
        paises: { co: true },
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Datos de<br>Contacto',
            title: 'Datos de contacto',
            icon: '',
            btnTxt: '',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos que puedes consultar, enlace en el pie de página.',
            infoType: 'info',
            isLast: false,
            storeAction: '',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 13,
                  number: true
                },
                phonePrefix: {
                  required: true
                }
              }
            }
          },
          {
            id: 2,
            type: 'component', // component || store.action
            optional: false,
            label: 'Instrucciones',
            title: 'Instrucciones',
            icon: 'fa-brands fa-whatsapp',
            btnTxt: 'Enviar instrucciones',
            info: '',
            infoType: '',
            isLast: true,
            storeAction: 'sac/tickets/clienteSolicitaInstrPorWhastapp',
            component: 'step-instrucciones',
            params: {
              instrucciones: [
                {
                  id: 1,
                  txt: 'Acercate a una sede o sucursal de <b>Bancolombia.</b>',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 2,
                  txt: 'Consigna a nombre de:<br><b>Casa de la Misericordia</b><br>Cuenta No. 824 4183 5414',
                  color: '',
                  imgSrc: ''
                }
              ]
            }
          }
        ]
      },
      { // LISTO - efectivo-nequi
        activo: true,
        id: 'efectivo-nequi',
        de: 'efectivo',
        para: 'nequi',
        desc: 'Recarga <b>nuestro Nequi</b> en un corresponsal.',
        instr: 'donar a través de Nequi en efectivo.',
        msgOk: 'Tu solicitud fue enviada.<br><b>Gracias por apoyarnos!</b>',
        canales: { efectivo: 1, nequi: 1 },
        mundo: false,
        paises: { co: true },
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Instrucciones',
            title: 'Instrucciones',
            icon: '',
            btnTxt: '',
            info: '',
            infoType: '',
            isLast: false,
            storeAction: '',
            component: 'step-instrucciones',
            params: {
              instrucciones: [
                {
                  id: 1,
                  txt: 'Acercate a un corresponsal de <b>Bancolombia o Nequi</b>.',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 2,
                  txt: 'Solicita enviar o recargar cualquiera de los siguientes números Nequi: <b>311 215 0129</b> ó <b>304 249 3689</b>',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 3,
                  txt: '<b>Opcional. </b>Si quieres, puedes reportar tu donación al número <b>Whatsapp: +57 301 366 0418</b>',
                  color: 'blue-grey lighten-2',
                  imgSrc: ''
                }
              ]
            }
          },
          {
            id: 2,
            type: 'component', // component || store.action
            optional: true,
            label: 'Enviar a tu<br>Whatsapp', // Enviar a<br>Whatsapp
            title: 'Enviar instrucciones a tu Whatsapp',
            icon: 'fa-brands fa-whatsapp',
            btnTxt: 'Enviar a Whatsapp',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos que puedes consultar, enlace en el pie de página.',
            infoType: 'info',
            isLast: true,
            storeAction: 'sac/tickets/clienteSolicitaInstrPorWhastapp',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true,
                  value: 'co',
                  readonly: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 13,
                  number: true
                },
                phonePrefix: {
                  required: true
                }
              }
            }
          }
        ]
      },
      { // yappy-yappy
        activo: false,
        id: 'yappy-yappy',
        de: 'yappy',
        para: 'yappy',
        desc: 'Usa <b>tu Yappy</b> para enviarnos una donación.',
        instr: '',
        msgOk: 'Directorio @MisionerosdelaMisericordia',
        canales: { yappy: 1 },
        mundo: false,
        paises: { pa: true },
        pasos: []
      },
      { // daviplata-daviplata
        activo: false,
        id: 'daviplata-daviplata',
        de: 'daviplata',
        para: 'daviplata',
        desc: 'Usa <b>tu Daviplata.</b> para enviarnos una donación.',
        instr: '',
        msgOk: '',
        canales: { daviplata: 1 },
        mundo: false,
        paises: { co: true },
        pasos: []
      },
      { // LISTO - efectivo-daviplata
        activo: true,
        id: 'efectivo-daviplata',
        de: 'efectivo',
        para: 'daviplata',
        desc: 'Recarga <b>nuestro Daviplata</b> en un corresponsal.',
        instr: 'donar a través de Daviplata en efectivo.',
        msgOk: 'Tu solicitud fue enviada.<br><b>Gracias por apoyarnos!</b>',
        canales: { efectivo: 1, daviplata: 1 },
        mundo: false,
        paises: { co: true },
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Instrucciones',
            title: 'Instrucciones',
            icon: '',
            btnTxt: '',
            info: '',
            infoType: '',
            isLast: false,
            storeAction: '',
            component: 'step-instrucciones',
            params: {
              instrucciones: [
                {
                  id: 1,
                  txt: 'Acercate a un corresponsal de <b>Daviplata</b>.',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 2,
                  txt: 'Solicita enviar o recargar cualquiera de los siguientes números Daviplata: <b>311 215 0129</b> ó <b>304 249 3689</b>',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 3,
                  txt: '<b>Opcional. </b>Si quieres, puedes reportar tu donación al número <b>Whatsapp: +57 301 366 0418</b>',
                  color: 'blue-grey lighten-2',
                  imgSrc: ''
                }
              ]
            }
          },
          {
            id: 2,
            type: 'component', // component || store.action
            optional: true,
            label: 'Enviar a tu<br>Whatsapp', // Enviar a<br>Whatsapp
            title: 'Enviar instrucciones a tu Whatsapp',
            icon: 'fa-brands fa-whatsapp',
            btnTxt: 'Enviar a Whatsapp',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos que puedes consultar, enlace en el pie de página.',
            infoType: 'info',
            isLast: true,
            storeAction: 'sac/tickets/clienteSolicitaInstrPorWhastapp',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true,
                  value: 'co',
                  readonly: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 13,
                  number: true
                },
                phonePrefix: {
                  required: true
                }
              }
            }
          }
        ]
      },
      { // LISTO - mensajero-domicilio
        activo: true,
        id: 'mensajero-domicilio',
        de: 'mensajero',
        para: 'domicilio',
        desc: 'Solicita <b>la visita</b> de un mensajero de la comunidad.',
        instr: '',
        msgOk: 'Tu solicitud fue enviada, pronto un servidor se comunicará contigo para coordinar fecha y hora. <b>Gracias por tu generosidad.</b>',
        canales: { efectivo: 1, whatsapp: 1 },
        mundo: false,
        paises: { co: true, pa: true },
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Direción',
            title: 'Direción',
            icon: '',
            btnTxt: '',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos, según la ley 222 de Habeas Data que puedes consultar en...',
            infoType: 'info',
            isLast: false,
            storeAction: '',
            component: 'step-direccion',
            params: {
              fields: {
                address: {
                  show: true,
                  required: true,
                  max: 75
                },
                address2: {
                  show: true,
                  required: false,
                  max: 50
                },
                city: {
                  show: true,
                  required: true
                },
                countryCode: {
                  show: true,
                  readonly: true,
                  required: true
                }
              },
              filter: {
                servicio: 'recaudoDomicilio'
              }
            }
          },
          {
            id: 2,
            type: 'component', // component || store.action
            optional: false,
            label: 'Datos de<br>contacto',
            title: 'Datos de contacto',
            icon: '',
            btnTxt: '',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos, según la ley 222 de Habeas Data que puedes consultar en...',
            infoType: 'info',
            isLast: false,
            storeAction: '',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 15,
                  number: true
                },
                phonePrefix: {
                  required: true
                }
              }
            }
          },
          {
            id: 3,
            type: 'component', // component || store.action
            optional: false,
            label: 'Donación',
            title: 'Donación',
            icon: 'fa-motorcycle',
            btnTxt: 'Solicitar visita',
            info: '',
            infoType: '',
            isLast: true,
            storeAction: 'campanias/donaciones/solicitaRecaudoDomicilio',
            component: 'step-donacion',
            params: {
              fields: {
                concept: {
                  show: false,
                  required: true,
                  value: 'Donación Campaña Misericordia Quiero'
                },
                currency: {
                  show: true,
                  required: true
                },
                value: {
                  show: true,
                  required: true
                }
              },
              aceptedCurrencies: ['USD'],
              countryCodSource: 'app/user/contacto/formDireccion',
              currencyFilterMethod: 'getter',
              valueParams: {
                co: {
                  COP: {
                    min: 15000,
                    max: 1000000,
                    step: 5000
                  }
                },
                pa: {
                  PAB: {
                    min: 1500,
                    max: 100000,
                    step: 1000
                  },
                  USD: {
                    min: 15,
                    max: 1000,
                    step: 5
                  }
                }
              }
            }
          }
        ]
      },
      { // entregar-cdlm
        activo: false,
        id: 'entregar-cdlm',
        de: 'entregar',
        para: 'cdlm',
        desc: '<b>Entrega </b> tu donación en una sede de la comunidad.',
        instr: '',
        msgOk: '',
        canales: { efectivo: 1, whatsapp: 1 },
        mundo: false,
        paises: { co: true, pa: true },
        pasos: []
      },
      { // bancolombia-wompi
        activo: false,
        id: 'bancolombia-wompi',
        de: 'bancolombia',
        para: 'wompi',
        desc: 'Transfiere desde tu <b>cuenta de Bancolombia</b>.',
        instr: '',
        msgOk: '',
        canales: { bancolombia: 1, wompi: 1 },
        mundo: false,
        paises: { co: true },
        pasos: []
      },
      { // corresponsal-wompi
        activo: false,
        id: 'corresponsal-wompi',
        de: 'corresponsal',
        para: 'wompi',
        desc: 'Consigna en un <b>corresponsal de Bancolombia</b>.',
        instr: '',
        msgOk: '',
        canales: { efectivo: 1, bancolombia: 1, wompi: 1 },
        mundo: false,
        paises: { co: true },
        pasos: []
      },
      { // pse-wompi
        activo: false,
        id: 'pse-wompi',
        de: 'pse',
        para: 'wompi',
        desc: 'Transfiere <b>desde tu cuenta bancaria</b> a través de PSE.',
        instr: '',
        msgOk: '',
        canales: { pse: 1, wompi: 1 },
        mundo: false,
        paises: { co: true },
        pasos: []
      },
      { // tarjetas-wompi
        activo: false,
        id: 'tarjetas-wompi',
        de: 'tarjetas',
        para: 'wompi',
        desc: 'Usa tus <b>tarjetas</b> de crédito o débito en Wompi.',
        instr: '',
        msgOk: '',
        canales: { wompi: 1 },
        mundo: true,
        paises: {},
        pasos: []
      },
      { // LISTO - tarjetas-paypal
        activo: true,
        id: 'tarjetas-paypal',
        de: 'tarjetas',
        para: 'paypal',
        desc: 'Usa tus <b>tarjetas</b> de crédito o débito en PayPal.',
        instr: '',
        msgOk: '',
        canales: { paypal: 1 },
        mundo: true,
        paises: {},
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Datos de<br>Contacto',
            title: 'Datos de contacto',
            icon: '',
            btnTxt: '',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos que puedes consultar, enlace en el pie de página.',
            infoType: 'info',
            isLast: false,
            storeAction: '',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 13,
                  number: true
                },
                phonePrefix: {
                  required: true
                }
              }
            }
          },
          {
            id: 2,
            type: 'component', // component || store.action
            optional: false,
            label: 'Donar con<br>PayPal',
            title: 'Donar con PayPal',
            icon: '',
            btnTxt: '',
            info: '',
            infoType: '',
            isLast: false,
            storeAction: '',
            component: 'step-pay-pal-donation',
            params: {
              instrucciones: [
                {
                  id: 1,
                  txt: 'Acercate a un corresponsal de <b>Daviplata</b>.',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 2,
                  txt: 'Solicita enviar o recargar cualquiera de los siguientes números Daviplata: <b>311 215 0129</b> ó <b>304 249 3689</b>',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 3,
                  txt: '<b>Opcional. </b>Si quieres, puedes reportar tu donación al número <b>Whatsapp: +57 301 366 0418</b>',
                  color: 'blue-grey lighten-2',
                  imgSrc: ''
                }
              ]
            }
          }
        ]
      },
      { // tarjetas-patreon
        activo: false,
        id: 'tarjetas-patreon',
        de: 'tarjetas',
        para: 'patreon',
        desc: 'Usa tus <b>tarjetas</b> de crédito o débito en Patreon.',
        instr: '',
        msgOk: 'Cobra automático el primer día de cada mes.',
        canales: { patreon: 1 },
        mundo: true,
        paises: {},
        pasos: []
      },
      { // tarjetas-payu
        activo: false,
        id: 'tarjetas-payu',
        de: 'tarjetas',
        para: 'payu',
        desc: 'Usa tus <b>tarjetas</b> de crédito o débito en PayU.',
        instr: '',
        msgOk: '',
        canales: { payu: 1 },
        mundo: true,
        paises: {},
        pasos: []
      },
      { // LISTO - efectivo-bancoGeneral
        activo: true,
        id: 'efectivo-bancoGeneral',
        de: 'efectivo',
        para: 'bancoGeneral',
        desc: '<b>Consigna o transfiere </b> al Banco General.',
        instr: '',
        msgOk: 'Hemos recibido tu donación,<b>gracias por tu generosidad.</b>',
        canales: { efectivo: 1, bancoGeneral: 1 },
        mundo: false,
        paises: { pa: true },
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Datos de<br>Contacto',
            title: 'Datos de contacto',
            icon: '',
            btnTxt: '',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos que puedes consultar, enlace en el pie de página.',
            infoType: 'info',
            isLast: false,
            storeAction: '',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true,
                  value: 'pa',
                  readonly: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 13,
                  number: true
                },
                phonePrefix: {
                  required: true,
                  readonly: true,
                  value: '+507'
                }
              }
            }
          },
          {
            id: 2,
            type: 'component', // component || store.action
            optional: false,
            label: 'Instrucciones',
            title: 'Instrucciones',
            icon: 'fa-brands fa-whatsapp',
            btnTxt: 'Enviar instrucciones',
            info: '',
            infoType: '',
            isLast: true,
            storeAction: 'sac/tickets/clienteSolicitaInstrPorWhastapp',
            component: 'step-instrucciones',
            params: {
              instrucciones: [
                {
                  id: 1,
                  txt: 'Acercate a una sede del<br><b>Banco General</b>.',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 2,
                  txt: 'Consigna a nombre de:<br><b>Misioneros de la Misericordia</b><br>Cuenta No. 0413 0181 72291',
                  color: '',
                  imgSrc: ''
                }
              ]
            }
          }
        ]
      },
      { // efectivo-bankOfAmerica
        activo: false,
        id: 'efectivo-bankOfAmerica',
        de: 'efectivo',
        para: 'bankOfAmerica',
        desc: '<b>Consigna o transfiere </b> en el Bank Of America.',
        instr: '',
        msgOk: '',
        canales: { efectivo: 1, bankOfAmerica: 1 },
        mundo: false,
        paises: { us: true },
        pasos: [
          {
            id: 1,
            type: 'component', // component || store.action
            optional: false,
            label: 'Datos de<br>Contacto',
            title: 'Datos de contacto',
            icon: '',
            btnTxt: '',
            info: 'La información de contacto es gestionada por la <b>Comunidad Casa de la Misericordia</b> de acuerdo con sus políticas de manejo de datos que puedes consultar, enlace en el pie de página.',
            infoType: 'info',
            isLast: false,
            storeAction: '',
            component: 'step-datos-contacto',
            params: {
              fields: {
                countryCode: {
                  show: true,
                  required: true,
                  value: 'pa',
                  readonly: true
                },
                name: {
                  show: true,
                  required: true,
                  max: 40,
                  min: 3
                },
                email: {
                  show: false,
                  required: false
                },
                phone: {
                  show: true,
                  required: true,
                  min: 7,
                  max: 13,
                  number: true
                },
                phonePrefix: {
                  required: true,
                  readonly: true,
                  value: '+507'
                }
              }
            }
          },
          {
            id: 2,
            type: 'component', // component || store.action
            optional: false,
            label: 'Donación',
            title: 'Donación',
            icon: '',
            btnTxt: '',
            info: '',
            infoType: '',
            isLast: false,
            storeAction: '',
            component: 'step-donacion',
            params: {
              fields: {
                concept: {
                  show: false,
                  required: true,
                  value: 'Donación Campaña Misericordia Quiero'
                },
                currency: {
                  show: true,
                  required: true
                },
                value: {
                  show: true,
                  required: true
                }
              },
              aceptedCurrencies: ['USD'],
              countryCodSource: 'app/user/contacto/formDireccion',
              valueParams: {
                us: {
                  USD: {
                    min: 15,
                    max: 1000,
                    step: 5
                  }
                }
              }
            }
          },
          {
            id: 3,
            type: 'component', // component || store.action
            optional: false,
            label: 'Instrucciones',
            title: 'Instrucciones',
            icon: 'fa-brands fa-whatsapp',
            btnTxt: 'Enviar instrucciones',
            info: '',
            infoType: '',
            isLast: true,
            storeAction: 'sac/tickets/clienteSolicitaInstrPorWhastapp',
            component: 'step-instrucciones',
            params: {
              instrucciones: [
                {
                  id: 1,
                  txt: 'Acercate a una sede del<br><b>Banco General</b>.',
                  color: '',
                  imgSrc: ''
                },
                {
                  id: 2,
                  txt: 'Consigna a nombre de:<br><b>Misioneros de la Misericordia</b><br>Cuenta No. 0413 0181 72291',
                  color: '',
                  imgSrc: ''
                }
              ]
            }
          }
        ]
      }
    ]
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (id) => {
      const index = state.items.findIndex(item => {
        return item.id === id
      })
      return index > -1
        ? state.items[index]
        : {
            id: 0,
            text: '',
            icon: 'fa-check-circle',
            img: '/imgs/camp-22/metas/meta-01.png',
            to: ''
          }
    },
    metodoById: (state) => (id) => {
      const index = state._metodos.findIndex(metodo => {
        return metodo.id === id
      })
      return index > -1
        ? state._metodos[index]
        : {
            activo: true,
            id: '',
            de: '',
            para: '',
            desc: '',
            msgOk: '',
            canales: [],
            mundo: false,
            paises: {},
            pasos: []
          }
    },
    activos (state) {
      return state.items.filter(item => {
        return item.activo === true
      })
    },
    byPaisSelected (state, getters, rootState, rootGetters) {
      // pais elegido
      const { codPais } = rootGetters._params

      return getters.activos.filter(item => {
        return item.mundo === true ||
          item.paises[codPais] !== undefined
      })
    },
    byPaisAndCanalSelected (state, getters, rootState, rootGetters) {
      // canal selected
      const { canalId } = rootGetters._params

      return getters.byPaisSelected.filter(item => {
        return item.canales[canalId] !== undefined
      })
    }
  },
  actions: {},
  mutations: {},
  modules: {}
}

export default metodos
