const modules = {
  namespaced: true,
  state: () => ({
    items: [
      {
        id: 'campanias',
        title: 'Campañas',
        icon: 'fa-hand-holding-hand',
        collections: [
          {
            id: 'camp_donaciones',
            typeIds: ['donaciones']
          }
        ]
      },
      {
        id: 'sac',
        title: 'Servicio al cliente',
        icon: 'fa-headset',
        collections: [
          {
            id: 'sac_tickets',
            typeIds: ['tickets']
          }
        ]
      }
    ]
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (itemId) => {
      const def = {
        id: '',
        icon: '',
        title: '',
        collections: []
      }

      if (itemId < 0 || itemId === undefined) return def

      const index = state.items.findIndex(item => {
        return item.id === itemId
      })

      return index > -1
        ? state.items[index]
        : def
    }
  },
  actions: {},
  mutations: {},
  modules: {}
}

export default modules
