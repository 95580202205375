import fbase from './fbase'
import flagIcon from './flagIcon'
import helper from './helper'
import localData from './localData'
import router from './router'
import store from './store'
import vuetify from './vuetify'
import shareIt from './shareIt'

const plugins = {
  fbase,
  flagIcon,
  helper,
  localData,
  router,
  shareIt,
  store,
  vuetify
}

export default plugins
