// import { collection, addDoc } from 'firebase/firestore'
// import { db } from './firebase'
import donaciones from './donaciones/donaciones'
import metas from './metas/metas'

const campanias = {
  namespaced: true,
  state: () => ({}),
  modules: { donaciones, metas }
}

export default campanias
