// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'

const permisos = {
  namespaced: true,
  state: () => ({
    items: []
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (id) => {
      const index = state.items.findIndex(item => {
        return item.id === id
      })
      return index > -1
        ? state.items[index]
        : {} // TODO: default
    },
    hasAdminAccess (state) {
      return true
      // TODO
      // return state.user.uid !== '' && state.permissions.adminAccess
    }
  },
  actions: {},
  mutations: {},
  modules: {}
}

export default permisos
