// import md5 from "md5"

const local = localStorage
// get, set, update fallan si key es vacío o nulo

// get | falla si no encuentra contenido
const get = key => {
  if (!key) return null

  const txt = local.getItem(key)
  return txt
    ? JSON.parse(txt)
    : null
}

// get | falla si no encuentra contenido
const getOr = (key1, key2) => {
  if (!key1 || !key2) return null

  const txt1 = get(key1)
  const txt2 = get(key2)
  const json1 = txt1 ? JSON.parse(txt1) : null
  const json2 = txt2 ? JSON.parse(txt2) : null

  return json1 || json2 || null
}

// set | si existe, reemplaza con vale
// set | elimina el objeto si value está vacio devuelve resolve
// set | falla si hay problemas con JSON.stringify o md5
const set = (key, value) => {
  if (!key) return null

  if (!value) {
    local.removeItem(key)
  } else {
    try {
      local.setItem(key, JSON.stringify(value))
    } catch {
      return null
    }
  }
}

// update | si existe asigna value
// falla si key o value está vacio
// falla si no pasa uno objeto
const update = (key, value) => {
  if (!key || !value) return null
  if (!Object.is(value)) return null

  try {
    let dataInLocal = local.getItem(key) || '{}'
    dataInLocal = JSON.parse(dataInLocal)
    const newData = Object.assign(dataInLocal, value)

    local.setItem(key, JSON.stringify(newData))
    return newData
  } catch {
    return null
  }
}

const localData = {
  get: get,
  getOr: getOr,
  set: set,
  update: update
}

export default localData
