import { donacionStatus } from './donacion'
import { uuid36 } from '@/plugins/helper'

const repository = {
  namespaced: true,
  actions: {
    'create-items' ({ rootGetters }, payload) {
      // const { filterId, moduleId, item, items, typeId } = payload
    },
    'delete-item' ({ state, dispatch }, payload) {
      // const { filterId, moduleId, item, items, typeId } = payload
      return new Promise((resolve, reject) => {
        if (payload.item === undefined && payload.items.length === 0) {
          reject(new Error('Debe elegir un item para eliminar.'))
          return null
        }

        // TODO: hasPermissions
        const entity = this.$db().model(payload.typeId)
        if (payload.item) {
          entity.delete(payload.item.id)
          entity.remove(state, payload.item.id)
          resolve('Item elimminado.')
        } else {
          let count = 0
          payload.items.forEach(item => {
            entity.delete(item.id)
            entity.remove(state, item.id)
            count++
          })

          let msg = ''
          if (count === 0) msg = 'Ningún item eliminado.'
          else if (count === 1) msg = '1 item eliminado.'
          else msg = `${count} items eliminados.`
          resolve(msg)
        }
      })
    },
    // controller
    'edit-item' ({ state, dispatch }, payload) {
      // const { filterId, moduleId, item, items, typeId } = payload
      return new Promise((resolve, reject) => {
        if (payload.item === undefined) {
          reject(new Error('Debe elegir un item para editar.'))
          return null
        }
        // TODO: hasPermissions
        const newRoute = {
          name: 'DocsActionMain',
          params: {
            actionId: 'edit-item',
            itemId: payload.item.id,
            moduleId: payload.moduleId,
            typeId: payload.typeId
          }
        }
        // TODO: almacenar filterId para regresar
        this.$router.push(newRoute)
      })
    },
    // controller
    'new-item' ({ state, rootGetters }, payload) {
      // const { filterId, moduleId, item, items, typeId } = payload
      return new Promise(resolve => {
        const data = payload.data === undefined ? {} : payload.data
        const userData = rootGetters['app/user/data']
        data.rolCreatedBy = userData.uid
        data.id = uuid36()

        const entity = this.$db().model(payload.typeId)
        entity.insert({ data })
          .then(docs => {
            const doc = docs[payload.typeId][0]
            const newRoute = {
              name: 'DocsActionMain',
              params: {
                actionId: 'new-item',
                itemId: doc.id,
                moduleId: payload.moduleId,
                typeId: payload.typeId
              }
            }
            this.$router.push(newRoute)
          })
      })
    },
    'save-items' ({ state, dispatch }, payload) {
      // const { filterId, moduleId, item, items, typeId } = payload
    },
    // controller
    'view-item' ({ state, dispatch }, payload) {
      // const { filterId, moduleId, item, items, typeId } = payload
      return new Promise((resolve, reject) => {
        // TODO: hasPermissions
        // const { itemId, moduleId, typeId } = payload
        const newRoute = {
          name: 'DocsActionMain',
          params: {
            actionId: 'view-item',
            itemId: payload.item.id,
            moduleId: payload.moduleId,
            typeId: payload.typeId
          }
        }
        // TODO: almacenar filterId para regresar
        this.$router.push(newRoute)
      })
    }
  },
  getters: {
    'items' (state) {
      return Object.values(state.data)
    },
    'canceladas' (state, getters) {
      return getters.items.filter(item => {
        return item.status === donacionStatus.cancelled
      })
    },
    'enCurso' (state, getters) {
      return getters.items.filter(item => {
        return item.status === donacionStatus.inProgress
      })
    },
    'listas' (state, getters) {
      return getters.items.filter(item => {
        return item.status === donacionStatus.ready
      })
    },
    'pendientes' (state, getters) {
      return getters.items.filter(item => {
        return item.status === donacionStatus.onHold
      })
    }
  }
}

export default repository
