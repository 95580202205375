// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
import canales from './canales/canales'
import metodos from './metodos/metodos'

const recaduos = {
  namespaced: true,
  state: () => ({}),
  getters: {},
  actions: {},
  mutations: {},
  modules: { canales, metodos }
}

export default recaduos
