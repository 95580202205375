<template>
  <router-view></router-view>
</template>

<script>
const vhw = document.getElementById('vhw')

export default {
  name: 'UiPwa',
  mounted () {
    window.addEventListener('resize', event => {
      this.$store.commit('setClient', {
        windowH: vhw.offsetHeight,
        windowW: vhw.offsetWidth
      })
    }, true)
    this.$store.commit('setClient', {
      windowH: vhw.offsetHeight,
      windowW: vhw.offsetWidth
    })
  }
}
</script>

<style>
  #vhw {
    display: block;
    position: fixed;
    top: 0px;
    bottom: 0px;
    left: 0px;
    right: 0px;
    z-index: -999;
  }

  .maxc {
    max-width: 1200px;
  }

  .mq-relative {
    position: relative;
  }

  h1.mq-font, h2, h3, h4, h5 {
    font-family: 'Forum', serif !important;
  }

  .mq-img-border { border: 1px solid #EF9A9A; }
  /* .mq-img-border { border: 0px solid #BBB; } */

  .mq-border-0 { border-width: 0px !important }
  .mq-border-1 { border-width: 1px }
  .mq-border-2 { border-width: 2px }
  .mq-border-3 { border-width: 3px }

  .mq-border-grey-b { border-color: #BBB }

  .mq-text-no-break {
    word-break: normal !important;
  }

  .mq-img-gradient {
    background-image: linear-gradient(0deg, rgba(229,57,53,0.5) 0%, rgba(229,57,53,0) 35%);
  }

  .mq-img-gradient-testimonio {
    background-image: linear-gradient(0deg, rgba(229,57,53,0.9) 0%, rgba(229,57,53,0.8) 10%, rgba(229,57,53,0.5) 25%, rgba(229,57,53,0.1) 50%, rgba(229,57,53,0) 100%);
  }

  .mq-img-gradient-grey {
    background-image: linear-gradient(0deg, rgba(0,0,0,0.1) 0%, rgba(0,0,0,0) 20%);
  }

  a.mq-inline-link {
    display: inline-block;
  }

  .mq-metodo-img-info-w {
    min-width: 264px;
  }

  .mq-metodo-info-maxw {
    max-width: 240px !important;
  }

  .mq-step-container {
    max-width: 544px;
  }

  .mq-shadow-hover {
    box-shadow: 0px 3px 1px -2px rgb(0 0 0 / 20%), 0px 2px 2px 0px rgb(0 0 0 / 14%), 0px 1px 5px 0px rgb(0 0 0 / 12%) !important;
  }

  .mq-shadow-hover:hover {
    box-shadow: 0px 3px 5px -1px rgb(0 0 0 / 20%), 0px 5px 8px 0px rgb(0 0 0 / 14%), 0px 1px 14px 0px rgb(0 0 0 / 12%) !important;
  }

  .text-decoration-none a {
    text-decoration: none !important;
  }

  .mq-icon-share a {
    padding: 20px !important;
  }

  a.share-icon {
    margin: 0px !important;
  }

  .debug {
    position: fixed;
    color: #BBB;
    bottom: 0px;
  }
</style>
