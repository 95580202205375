<template>
  <v-app :class="bp.name">
    <mq-app-bar v-on:showmenu="showMenu = !showMenu"></mq-app-bar>

    <!-- <v-navigation-drawer
      app
      right
      temporary
      width="300px"
      v-model="showMenu"
    >
      <v-list-item>
        <pre>algo</pre>
      </v-list-item>
    </v-navigation-drawer> -->

    <v-main class="mq-img-gradient-grey">
      <!-- Landing Page -->
      <mq-22-a v-if="landingId === '2022'"></mq-22-a>
      <mq-dia-dia v-else></mq-dia-dia>
    </v-main>

    <mq-footer></mq-footer>

    <!-- bottom sheet -->
    <v-bottom-sheet
      content-class="mq-relative"
      :fullscreen="showBottomSheetFull"
      inset
      overlay-color="primary darken-3"
      scrollable
      v-model="showBottomSheet"
      width="auto"
    >
      <router-view name="bottomSheets"></router-view>
    </v-bottom-sheet>

    <!-- dialog -->
    <v-dialog
      :content-class="bp.smAndDown ? '' : 'rounded-lg'"
      :fullscreen="showDialogOnFullScreen"
      inset
      overlay-color="primary"
      v-model="showDialog"
      width="auto"
    >
      <router-view name="dialogSubView"></router-view>
    </v-dialog>

    <!-- whatsapp btn -->
    <v-btn @click="openWhatsapp"
      bottom class="ma-3" color="success" fab fixed large right
    >
      <v-icon large>fa-brands fa-whatsapp</v-icon>
    </v-btn>
  </v-app>
</template>

<script>
import router from '../../plugins/router'
import vuetify from '../../plugins/vuetify'

import * as LP from '../public/landingPages/index'
import MqAppBar from '@/ui/public/layout/MqAppBar'
import MqFooter from '@/ui/public/layout/MqFooter'

export default {
  name: 'HomeFrame',
  components: {
    MqAppBar,
    MqFooter,
    ...LP
  },
  data: () => ({
    items: [
      ''
    ],
    lastRoutePath: '',
    showMenu: false
  }),
  computed: {
    bp () {
      return vuetify.framework.breakpoint
    },
    landingId () {
      return this.$route.params.landingId || false
    },
    showBottomSheet: {
      get () {
        return this.$route.meta.showBottomSheet || false
      },
      set (value) {
        this.goBack()
      }
    },
    showBottomSheetFull () {
      const views = {
        xs: true,
        sm: true,
        md: false,
        lg: false,
        xl: false
      }
      return views[this.bp.name]
    },
    showDialog: {
      get () {
        return this.$route.meta.showDialog || false
      },
      set (value) {
        this.goBack()
      }
    },
    showDialogOnFullScreen () {
      const views = {
        xs: true,
        sm: false,
        md: false,
        lg: false,
        xl: false
      }
      return views[this.bp.name]
    }
  },
  methods: {
    goBack () {
      if (this.lastRoutePath) {
        router.push(this.lastRoutePath)
      } else {
        if (this.$route.name !== 'home') {
          router.push({ name: 'home' })
        }
      }
    },
    openWhatsapp () {
      const msg = 'Hola, me pueden dar más información sobre "Misericordia Quiero"'
      this.$store.dispatch('sac/openWhatsapp', { msg })
    }
  }
}
</script>
