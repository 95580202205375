import { addDoc, collection } from 'firebase/firestore'
import { db } from '@/plugins/fbase'
// modulos
import efectivo from '../../integraciones/efectivo/efectivo'
import paypal from '../../integraciones/paypal/paypal'

const donaciones = {
  namespaced: true,
  state: () => ({
    _params: {
      collectionName: 'socios_donaciones',
      typeId: 'donaciones',
      typeVersion: 1
    },
    formDonacion: {
      concept: '',
      currency: '',
      value: 0
    },
    items: []
  }),
  actions: {
    generarNuevaDonacion ({ state, dispatch }, payload) {
      return new Promise((resolve, reject) => {
        const { typeId } = state._params

        // Busca los datos en cada ruta indicada en los parametros
        dispatch('app/types/generateDocBaseForTypeId', typeId, { root: true })
          .then(docGenerated => {
            // informacion del ticket
            docGenerated.donacion = { ...state.formDonacion }

            console.log(docGenerated)
            resolve(docGenerated)
          })
          .catch(e => { reject(e) })
      })
    },
    getFormDonacionData ({ state }, payload) {
      return new Promise(resolve => {
        resolve({ donacion: state.formDonacion })
      })
    },
    setFormDonacion ({ state }, formDonacion) {
      return new Promise(resolve => {
        Object.keys(formDonacion).forEach(key => {
          state.formDonacion[key] = formDonacion[key]
        })
        resolve()
      })
    },
    solicitaRecaudoDomicilio ({ state, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        const { current } = this.$router.history
        const contacto = rootGetters['app/user/contacto/formContacto']
        const direccion = rootGetters['app/user/contacto/formDireccion']
        const ticket = rootGetters['sac/tickets/form']
        const donacion = rootGetters['campanias/donaciones/formDonacion']

        const docBase = {
          contacto: { ...contacto },
          direccion: { ...direccion },
          donacion: { ...donacion },
          message: ticket.message
        }

        docBase.status = 1

        docBase.action = {}
        docBase.action.id = 'solicitaRecaudoDomicilio'
        docBase.action.route = { name: current.name, params: current.params }

        const { collectionName } = state._params
        addDoc(collection(db, collectionName), docBase)
          .then(doc => {
            resolve()
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  },
  getters: {
    canceladas (state) {
      return []
    },
    enCurso (state) {
      return []
    },
    formDonacion (state) {
      return state.formDonacion
    },
    listas (state) {
      return []
    },
    pendientes (state, getters, rootState) {
      // const User =
      // conocen orm
      // console.log('pendientes', this)
      return []
    }
  },
  modules: { efectivo, paypal }
}

export default donaciones
