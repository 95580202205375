import { addDoc, collection } from 'firebase/firestore'
import { db } from '../../../plugins/fbase'

const efectivo = {
  namespaced: true,
  state: () => ({
    _params: {
      canalId: 'efectivo',
      typeVersion: 1
    }
  }),
  actions: {
    clienteSolicitaAyudaPorWhatsapp ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        const { collectionName } = state._params
        const { current } = this.$router.history

        dispatch('generarNuevoTicket')
          .then(newTicket => {
            newTicket.action = {}
            newTicket.action.id = 'clienteSolicitaAyudaPorWhatsapp'
            newTicket.action.route = { name: current.name, params: current.params }

            addDoc(collection(db, collectionName), newTicket)
              .then(doc => {
                const { name, phone, phonePrefix } = newTicket.contacto

                const parte1 = name === ''
                  ? 'Hola, me gustaría apoyar la comunidad con una donación.'
                  : `Hola, me llamo ${name} quiero apoyar la comunidad con una donación.`

                const parte2 = phone === ''
                  ? ''
                  : ` Mi teléfono es ${phonePrefix} ${phone}`

                const msg = parte1 + parte2

                dispatch('sac/openWhatsapp', { msg }, { root: true })
                resolve()
              })
              .catch(e => {
                reject(e)
              })
          })
      })
    },
    domicilio () {
      // añadir informacion de direccion
    },
    sede () {
      // añadir informacion de la sede
    }
  }
}

export default efectivo
