// import { Timestamp } from 'firebase/firestore'
import { tStamp, uuid36 } from '@/plugins/helper'
import { Model } from '@vuex-orm/core'

class Item extends Model {
  static entity = 'Item'

  static typeKey = 'typeId'

  static fields () {
    return {
      id: this.uid(() => uuid36()),
      rolCreatedBy: this.string(''),
      rolUpdatedBy: this.string(''),
      tmCreatedAt: this.attr(tStamp()),
      tmUpdatedAt: this.attr('')
    }
  }
}

export default Item
