// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'
import fotos from './fotos/fotos'
import logros from './logros/logros'
import testimonios from './testimonios/testimonios'

const comunidad = {
  namespaced: true,
  state: () => ({}),
  modules: { fotos, logros, testimonios }
}

export default comunidad
