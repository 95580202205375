import tickets from './tickets/tickets'

const sac = {
  namespaced: true,
  state: () => ({
    params: {
      whatsappNum: '573105752369' // 573013660418
    }
  }),
  actions: { // { dispatch, commit, getters, rootGetters }
    openWhatsapp ({ state }, payload) {
      const txtEncode = encodeURIComponent(payload.msg)
      const url = `https://api.whatsapp.com/send?phone=${state.params.whatsappNum}&text=${txtEncode}`
      window.open(url, '_blank').focus()
    }
  },
  modules: { tickets }
}

export default sac
