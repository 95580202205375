import { Timestamp } from 'firebase/firestore'

export const lowerNormalize = txt => {
  // eslint-disable-next-line no-misleading-character-class
  const rx = /([^n\u0300-\u036f]|n(?!\u0303(?![\u0300-\u036f])))[\u0300-\u036f]+/gi
  return txt
    .toLowerCase()
    .normalize('NFD')
    .replace(rx, '$1')
    .normalize()
}

export const uuid36 = () => {
  return Math.random().toString(36).slice(2)
}

export const tStamp = () => {
  return Timestamp.fromDate(new Date())
}

const helper = { lowerNormalize }
export default helper
