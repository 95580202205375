// import geopi from 'geoip-lite'
// https://es.wikipedia.org/wiki/ISO_3166-1
// https://es.wikipedia.org/wiki/ISO_3166-2
// https://es.wikipedia.org/wiki/ISO_3166-3
// https://restcountries.eu/
// https://es.wikipedia.org/wiki/ISO_639-1 || estandar idiomas
// https://docs.google.com/spreadsheets/d/1KOAVMF339uoca3QkLTLfmS1T94K4kNZ51s_Ycekfq48/edit#gid=0 || recursos
// https://github.com/OpenBookPrices/country-data
// params: no cambian en ejecución. opctions: cambian en ejecucion
// (currencies: \[)([\n\s]+)(['A-Z]+)(\s+)(\],\n) >> $1$3$5

// eslint-disable-next-line
import Vue from 'vue'
// import infoo from '../plugins/infoo'

const helpers = {
  namespaced: true,
  state: () => {
    return {
      clientLocation1: {
        status: '',
        country: '',
        countryCode: '',
        region: '',
        regionName: '',
        city: '',
        zip: '',
        lat: 0,
        lon: 0,
        timezone: '',
        isp: '',
        org: '',
        as: '',
        query: ''
      },
      clientLocation: {
        latitude: '',
        longitude: '',
        continent: '',
        lookupSource: '',
        continentCode: '',
        localityLanguageRequested: '',
        city: '',
        countryName: '',
        countryCode: '',
        postcode: '',
        principalSubdivision: '',
        principalSubdivisionCode: '',
        plusCode: '',
        locality: '',
        localityInfo: {
          administrative: [],
          informative: []
        }
      },
      phonePrefix: [
        {
          code: 'af',
          dial_code: '+93',
          name_en: 'Afghanistan +93',
          name_es: 'Afganistán +93'
        },
        {
          code: 'al',
          dial_code: '+355',
          name_en: 'Albania +355',
          name_es: 'Albania +355'
        },
        {
          code: 'dz',
          dial_code: '+213',
          name_en: 'Algeria +213',
          name_es: 'Argelia +213'
        },
        {
          code: 'as',
          dial_code: '+1684',
          name_en: 'AmericanSamoa +1684',
          name_es: 'Samoa Americana +1684'
        },
        {
          code: 'ad',
          dial_code: '+376',
          name_en: 'Andorra +376',
          name_es: 'Andorra +376'
        },
        {
          code: 'ao',
          dial_code: '+244',
          name_en: 'Angola +244',
          name_es: 'Angola +244'
        },
        {
          code: 'ai',
          dial_code: '+1264',
          name_en: 'Anguilla +1264',
          name_es: 'Anguilla +1264'
        },
        {
          code: 'aq',
          dial_code: '+672',
          name_en: 'Antarctica +672',
          name_es: 'Antártida +672'
        },
        {
          code: 'ag',
          dial_code: '+1268',
          name_en: 'Antigua and Barbuda +1268',
          name_es: 'Antigua y Barbuda +1268'
        },
        {
          code: 'ar',
          dial_code: '+54',
          name_en: 'Argentina +54',
          name_es: 'Argentina +54'
        },
        {
          code: 'am',
          dial_code: '+374',
          name_en: 'Armenia +374',
          name_es: 'Armenia +374'
        },
        {
          code: 'aw',
          dial_code: '+297',
          name_en: 'Aruba +297',
          name_es: 'Aruba +297'
        },
        {
          code: 'au',
          dial_code: '+61',
          name_en: 'Australia +61',
          name_es: 'Australia +61'
        },
        {
          code: 'at',
          dial_code: '+43',
          name_en: 'Austria +43',
          name_es: 'Austria +43'
        },
        {
          code: 'az',
          dial_code: '+994',
          name_en: 'Azerbaijan +994',
          name_es: 'Azerbaiyán +994'
        },
        {
          code: 'bs',
          dial_code: '+1242',
          name_en: 'Bahamas +1242',
          name_es: 'Bahamas +1242'
        },
        {
          code: 'bh',
          dial_code: '+973',
          name_en: 'Bahrain +973',
          name_es: 'Baréin +973'
        },
        {
          code: 'bd',
          dial_code: '+880',
          name_en: 'Bangladesh +880',
          name_es: 'Banglades +880'
        },
        {
          code: 'bb',
          dial_code: '+1246',
          name_en: 'Barbados +1246',
          name_es: 'Barbados +1246'
        },
        {
          code: 'by',
          dial_code: '+375',
          name_en: 'Belarus +375',
          name_es: 'Bielorrusia +375'
        },
        {
          code: 'be',
          dial_code: '+32',
          name_en: 'Belgium +32',
          name_es: 'Bélgica +32'
        },
        {
          code: 'bz',
          dial_code: '+501',
          name_en: 'Belize +501',
          name_es: 'Belice +501'
        },
        {
          code: 'bj',
          dial_code: '+229',
          name_en: 'Benin +229',
          name_es: 'Benin +229'
        },
        {
          code: 'bm',
          dial_code: '+1441',
          name_en: 'Bermuda +1441',
          name_es: 'Bermudas +1441'
        },
        {
          code: 'bt',
          dial_code: '+975',
          name_en: 'Bhutan +975',
          name_es: 'Butan +975'
        },
        {
          code: 'bo',
          dial_code: '+591',
          name_en: 'Bolivia +591',
          name_es: 'Bolivia +591'
        },
        {
          code: 'ba',
          dial_code: '+387',
          name_en: 'Bosnia and Herzegovina +387',
          name_es: 'Bosnia-Herzegovina +387'
        },
        {
          code: 'bw',
          dial_code: '+267',
          name_en: 'Botswana +267',
          name_es: 'Botsuana +267'
        },
        {
          code: 'br',
          dial_code: '+55',
          name_en: 'Brazil +55',
          name_es: 'Brasil +55'
        },
        {
          code: 'io',
          dial_code: '+246',
          name_en: 'British Indian Ocean Territory +246',
          name_es: 'Territorio Británico del Océano Índico +246'
        },
        {
          code: 'bn',
          dial_code: '+673',
          name_en: 'Brunei Darussalam +673',
          name_es: 'Brunei +673'
        },
        {
          code: 'bg',
          dial_code: '+359',
          name_en: 'Bulgaria +359',
          name_es: 'Bulgaria +359'
        },
        {
          code: 'bf',
          dial_code: '+226',
          name_en: 'Burkina Faso +226',
          name_es: 'Burkina Faso +226'
        },
        {
          code: 'bi',
          dial_code: '+257',
          name_en: 'Burundi +257',
          name_es: 'Burundi +257'
        },
        {
          code: 'kh',
          dial_code: '+855',
          name_en: 'Cambodia +855',
          name_es: 'Camboya +855'
        },
        {
          code: 'cm',
          dial_code: '+237',
          name_en: 'Cameroon +237',
          name_es: 'Camerún +237'
        },
        {
          code: 'ca',
          dial_code: '+1',
          name_en: 'Canada +1',
          name_es: 'Canadá +1'
        },
        {
          code: 'cv',
          dial_code: '+238',
          name_en: 'Cape Verde +238',
          name_es: 'Cabo Verde +238'
        },
        {
          code: 'ky',
          dial_code: '+ 345',
          name_en: 'Cayman Islands + 345',
          name_es: 'Islas Caimán + 345'
        },
        {
          code: 'cf',
          dial_code: '+236',
          name_en: 'Central African Republic +236',
          name_es: 'República Centroafricana +236'
        },
        {
          code: 'td',
          dial_code: '+235',
          name_en: 'Chad +235',
          name_es: 'Chad +235'
        },
        {
          code: 'cl',
          dial_code: '+56',
          name_en: 'Chile +56',
          name_es: 'Chile +56'
        },
        {
          code: 'cn',
          dial_code: '+86',
          name_en: 'China +86',
          name_es: 'China +86'
        },
        {
          code: 'cx',
          dial_code: '+61',
          name_en: 'Christmas Island +61',
          name_es: 'Isla de Navidad +61'
        },
        {
          code: 'cc',
          dial_code: '+61',
          name_en: 'Cocos (Keeling) Islands +61',
          name_es: 'Islas Cocos +61'
        },
        {
          code: 'co',
          dial_code: '+57',
          name_en: 'Colombia +57',
          name_es: 'Colombia +57'
        },
        {
          code: 'km',
          dial_code: '+269',
          name_en: 'Comoros +269',
          name_es: 'Comoras +269'
        },
        {
          code: 'cg',
          dial_code: '+242',
          name_en: 'Congo +242',
          name_es: 'Congo +242'
        },
        {
          code: 'cd',
          dial_code: '+243',
          name_en: 'Congo, The Democratic Republic of the +243',
          name_es: 'República Democrática del Congo +243'
        },
        {
          code: 'ck',
          dial_code: '+682',
          name_en: 'Cook Islands +682',
          name_es: 'Islas Cook +682'
        },
        {
          code: 'cr',
          dial_code: '+506',
          name_en: 'Costa Rica +506',
          name_es: 'Costa Rica +506'
        },
        {
          code: 'ci',
          dial_code: '+225',
          name_en: 'Cote d\'Ivoire +225',
          name_es: 'Costa de Marfil +225'
        },
        {
          code: 'hr',
          dial_code: '+385',
          name_en: 'Croatia +385',
          name_es: 'Croacia +385'
        },
        {
          code: 'cu',
          dial_code: '+53',
          name_en: 'Cuba +53',
          name_es: 'Cuba +53'
        },
        {
          code: 'cy',
          dial_code: '+537',
          name_en: 'Cyprus +537',
          name_es: 'Chipre +537'
        },
        {
          code: 'cz',
          dial_code: '+420',
          name_en: 'Czechia +420',
          name_es: 'Chequia +420'
        },
        {
          code: 'dk',
          dial_code: '+45',
          name_en: 'Denmark +45',
          name_es: 'Dinamarca +45'
        },
        {
          code: 'dj',
          dial_code: '+253',
          name_en: 'Djibouti +253',
          name_es: 'Yibuti +253'
        },
        {
          code: 'dm',
          dial_code: '+1767',
          name_en: 'Dominica +1767',
          name_es: 'Dominica +1767'
        },
        {
          code: 'do',
          dial_code: '+1849',
          name_en: 'Dominican Republic +1849',
          name_es: 'República Dominicana +1849'
        },
        {
          code: 'ec',
          dial_code: '+593',
          name_en: 'Ecuador +593',
          name_es: 'Ecuador +593'
        },
        {
          code: 'eg',
          dial_code: '+20',
          name_en: 'Egypt +20',
          name_es: 'Egipto +20'
        },
        {
          code: 'sv',
          dial_code: '+503',
          name_en: 'El Salvador +503',
          name_es: 'El Salvador +503'
        },
        {
          code: 'gq',
          dial_code: '+240',
          name_en: 'Equatorial Guinea +240',
          name_es: 'Guinea Ecuatorial +240'
        },
        {
          code: 'er',
          dial_code: '+291',
          name_en: 'Eritrea +291',
          name_es: 'Eritrea +291'
        },
        {
          code: 'ee',
          dial_code: '+372',
          name_en: 'Estonia +372',
          name_es: 'Estonia +372'
        },
        {
          code: 'et',
          dial_code: '+251',
          name_en: 'Ethiopia +251',
          name_es: 'Etiopía +251'
        },
        {
          code: 'fk',
          dial_code: '+500',
          name_en: 'Falkland Islands (Malvinas) +500',
          name_es: 'Islas Malvinas +500'
        },
        {
          code: 'fo',
          dial_code: '+298',
          name_en: 'Faroe Islands +298',
          name_es: 'Islas Feroe +298'
        },
        {
          code: 'fj',
          dial_code: '+679',
          name_en: 'Fiji +679',
          name_es: 'Fiyi +679'
        },
        {
          code: 'fi',
          dial_code: '+358',
          name_en: 'Finland +358',
          name_es: 'Finlandia +358'
        },
        {
          code: 'fr',
          dial_code: '+33',
          name_en: 'France +33',
          name_es: 'Francia +33'
        },
        {
          code: 'gf',
          dial_code: '+594',
          name_en: 'French Guiana +594',
          name_es: 'Guayana Francesa +594'
        },
        {
          code: 'pf',
          dial_code: '+689',
          name_en: 'French Polynesia +689',
          name_es: 'Polinesia Francesa +689'
        },
        {
          code: 'ga',
          dial_code: '+241',
          name_en: 'Gabon +241',
          name_es: 'Gabón +241'
        },
        {
          code: 'gm',
          dial_code: '+220',
          name_en: 'Gambia +220',
          name_es: 'Gambia +220'
        },
        {
          code: 'ge',
          dial_code: '+995',
          name_en: 'Georgia +995',
          name_es: 'Georgia +995'
        },
        {
          code: 'de',
          dial_code: '+49',
          name_en: 'Germany +49',
          name_es: 'Alemania +49'
        },
        {
          code: 'gh',
          dial_code: '+233',
          name_en: 'Ghana +233',
          name_es: 'Ghana +233'
        },
        {
          code: 'gi',
          dial_code: '+350',
          name_en: 'Gibraltar +350',
          name_es: 'Gibraltar +350'
        },
        {
          code: 'gr',
          dial_code: '+30',
          name_en: 'Greece +30',
          name_es: 'Grecia +30'
        },
        {
          code: 'gl',
          dial_code: '+299',
          name_en: 'Greenland +299',
          name_es: 'Groenlandia +299'
        },
        {
          code: 'gd',
          dial_code: '+1473',
          name_en: 'Grenada +1473',
          name_es: 'Granada +1473'
        },
        {
          code: 'gp',
          dial_code: '+590',
          name_en: 'Guadeloupe +590',
          name_es: 'Guadalupe +590'
        },
        {
          code: 'gu',
          dial_code: '+1671',
          name_en: 'Guam +1671',
          name_es: 'Guam +1671'
        },
        {
          code: 'gt',
          dial_code: '+502',
          name_en: 'Guatemala +502',
          name_es: 'Guatemala +502'
        },
        {
          code: 'gg',
          dial_code: '+44',
          name_en: 'Guernsey +44',
          name_es: 'Guernsey +44'
        },
        {
          code: 'gn',
          dial_code: '+224',
          name_en: 'Guinea +224',
          name_es: 'Guinea +224'
        },
        {
          code: 'gw',
          dial_code: '+245',
          name_en: 'Guinea-Bissau +245',
          name_es: 'Guinea-Bisau +245'
        },
        {
          code: 'gy',
          dial_code: '+595',
          name_en: 'Guyana +595',
          name_es: 'Guyana +595'
        },
        {
          code: 'ht',
          dial_code: '+509',
          name_en: 'Haiti +509',
          name_es: 'Haití +509'
        },
        {
          code: 'va',
          dial_code: '+379',
          name_en: 'Holy See (Vatican City State) +379',
          name_es: 'Ciudad del Vaticano +379'
        },
        {
          code: 'hn',
          dial_code: '+504',
          name_en: 'Honduras +504',
          name_es: 'Honduras +504'
        },
        {
          code: 'hk',
          dial_code: '+852',
          name_en: 'Hong Kong +852',
          name_es: 'Hong Kong +852'
        },
        {
          code: 'hu',
          dial_code: '+36',
          name_en: 'Hungary +36',
          name_es: 'Hungría +36'
        },
        {
          code: 'is',
          dial_code: '+354',
          name_en: 'Iceland +354',
          name_es: 'Islandia +354'
        },
        {
          code: 'in',
          dial_code: '+91',
          name_en: 'India +91',
          name_es: 'India +91'
        },
        {
          code: 'id',
          dial_code: '+62',
          name_en: 'Indonesia +62',
          name_es: 'Indonesia +62'
        },
        {
          code: 'ir',
          dial_code: '+98',
          name_en: 'Iran, Islamic Republic of +98',
          name_es: 'Irán +98'
        },
        {
          code: 'iq',
          dial_code: '+964',
          name_en: 'Iraq +964',
          name_es: 'Iraq +964'
        },
        {
          code: 'ie',
          dial_code: '+353',
          name_en: 'Ireland +353',
          name_es: 'Irlanda +353'
        },
        {
          code: 'im',
          dial_code: '+44',
          name_en: 'Isle of Man +44',
          name_es: 'Isla de Man +44'
        },
        {
          code: 'il',
          dial_code: '+972',
          name_en: 'Israel +972',
          name_es: 'Israel +972'
        },
        {
          code: 'it',
          dial_code: '+39',
          name_en: 'Italy +39',
          name_es: 'Italia +39'
        },
        {
          code: 'jm',
          dial_code: '+1876',
          name_en: 'Jamaica +1876',
          name_es: 'Jamaica +1876'
        },
        {
          code: 'jp',
          dial_code: '+81',
          name_en: 'Japan +81',
          name_es: 'Japón +81'
        },
        {
          code: 'je',
          dial_code: '+44',
          name_en: 'Jersey +44',
          name_es: 'Jersey +44'
        },
        {
          code: 'jo',
          dial_code: '+962',
          name_en: 'Jordan +962',
          name_es: 'Jordania +962'
        },
        {
          code: 'kz',
          dial_code: '+7',
          name_en: 'Kazakhstan +7',
          name_es: 'Kazajistán +7'
        },
        {
          code: 'ke',
          dial_code: '+254',
          name_en: 'Kenya +254',
          name_es: 'Kenia +254'
        },
        {
          code: 'ki',
          dial_code: '+686',
          name_en: 'Kiribati +686',
          name_es: 'Kiribati +686'
        },
        {
          code: 'kp',
          dial_code: '+850',
          name_en: 'Korea, Democratic People\'s Republic of +850',
          name_es: 'Corea del Norte +850'
        },
        {
          code: 'kr',
          dial_code: '+82',
          name_en: 'Korea, Republic of +82',
          name_es: 'Corea del Sur +82'
        },
        {
          code: 'xk',
          dial_code: '+383',
          name_en: 'Kosovo +383',
          name_es: 'Kosovo +383'
        },
        {
          code: 'kw',
          dial_code: '+965',
          name_en: 'Kuwait +965',
          name_es: 'Kuwait +965'
        },
        {
          code: 'kg',
          dial_code: '+996',
          name_en: 'Kyrgyzstan +996',
          name_es: 'Kirguistán +996'
        },
        {
          code: 'la',
          dial_code: '+856',
          name_en: 'Lao People\'s Democratic Republic +856',
          name_es: 'Laos +856'
        },
        {
          code: 'lv',
          dial_code: '+371',
          name_en: 'Latvia +371',
          name_es: 'Letonia +371'
        },
        {
          code: 'lb',
          dial_code: '+961',
          name_en: 'Lebanon +961',
          name_es: 'Líbano +961'
        },
        {
          code: 'ls',
          dial_code: '+266',
          name_en: 'Lesotho +266',
          name_es: 'Lesoto +266'
        },
        {
          code: 'lr',
          dial_code: '+231',
          name_en: 'Liberia +231',
          name_es: 'Liberia +231'
        },
        {
          code: 'ly',
          dial_code: '+218',
          name_en: 'Libyan Arab Jamahiriya +218',
          name_es: 'Libia +218'
        },
        {
          code: 'li',
          dial_code: '+423',
          name_en: 'Liechtenstein +423',
          name_es: 'Liechtenstein +423'
        },
        {
          code: 'lt',
          dial_code: '+370',
          name_en: 'Lithuania +370',
          name_es: 'Lituania +370'
        },
        {
          code: 'lu',
          dial_code: '+352',
          name_en: 'Luxembourg +352',
          name_es: 'Luxemburgo +352'
        },
        {
          code: 'mo',
          dial_code: '+853',
          name_en: 'Macao +853',
          name_es: 'Macao +853'
        },
        {
          code: 'mk',
          dial_code: '+389',
          name_en: 'Macedonia, The Former Yugoslav Republic of +389',
          name_es: 'República de Macedonia +389'
        },
        {
          code: 'mg',
          dial_code: '+261',
          name_en: 'Madagascar +261',
          name_es: 'Madagascar +261'
        },
        {
          code: 'mw',
          dial_code: '+265',
          name_en: 'Malawi +265',
          name_es: 'Malaui +265'
        },
        {
          code: 'my',
          dial_code: '+60',
          name_en: 'Malaysia +60',
          name_es: 'Malasia +60'
        },
        {
          code: 'mv',
          dial_code: '+960',
          name_en: 'Maldives +960',
          name_es: 'Maldivas +960'
        },
        {
          code: 'ml',
          dial_code: '+223',
          name_en: 'Mali +223',
          name_es: 'Malí +223'
        },
        {
          code: 'mt',
          dial_code: '+356',
          name_en: 'Malta +356',
          name_es: 'Malta +356'
        },
        {
          code: 'mh',
          dial_code: '+692',
          name_en: 'Marshall Islands +692',
          name_es: 'Islas Marshall +692'
        },
        {
          code: 'mq',
          dial_code: '+596',
          name_en: 'Martinique +596',
          name_es: 'Martinica +596'
        },
        {
          code: 'mr',
          dial_code: '+222',
          name_en: 'Mauritania +222',
          name_es: 'Mauritania +222'
        },
        {
          code: 'mu',
          dial_code: '+230',
          name_en: 'Mauritius +230',
          name_es: 'Mauricio +230'
        },
        {
          code: 'yt',
          dial_code: '+262',
          name_en: 'Mayotte +262',
          name_es: 'Mayotte +262'
        },
        {
          code: 'mx',
          dial_code: '+52',
          name_en: 'Mexico +52',
          name_es: 'México +52'
        },
        {
          code: 'fm',
          dial_code: '+691',
          name_en: 'Micronesia, Federated States of +691',
          name_es: 'Estados Federados de Micronesia +691'
        },
        {
          code: 'md',
          dial_code: '+373',
          name_en: 'Moldova, Republic of +373',
          name_es: 'Moldavia +373'
        },
        {
          code: 'mc',
          dial_code: '+377',
          name_en: 'Monaco +377',
          name_es: 'Monaco +377'
        },
        {
          code: 'mn',
          dial_code: '+976',
          name_en: 'Mongolia +976',
          name_es: 'Mongolia +976'
        },
        {
          code: 'me',
          dial_code: '+382',
          name_en: 'Montenegro +382',
          name_es: 'Montenegro +382'
        },
        {
          code: 'ms',
          dial_code: '+1664',
          name_en: 'Montserrat +1664',
          name_es: 'Montserrat +1664'
        },
        {
          code: 'ma',
          dial_code: '+212',
          name_en: 'Morocco +212',
          name_es: 'Marruecos +212'
        },
        {
          code: 'mz',
          dial_code: '+258',
          name_en: 'Mozambique +258',
          name_es: 'Mozambique +258'
        },
        {
          code: 'mm',
          dial_code: '+95',
          name_en: 'Myanmar +95',
          name_es: 'Birmania +95'
        },
        {
          code: 'na',
          dial_code: '+264',
          name_en: 'Namibia +264',
          name_es: 'Namibia +264'
        },
        {
          code: 'nr',
          dial_code: '+674',
          name_en: 'Nauru +674',
          name_es: 'Nauru +674'
        },
        {
          code: 'np',
          dial_code: '+977',
          name_en: 'Nepal +977',
          name_es: 'Nepal +977'
        },
        {
          code: 'nl',
          dial_code: '+31',
          name_en: 'Netherlands +31',
          name_es: 'Holanda +31'
        },
        {
          code: 'an',
          dial_code: '+599',
          name_en: 'Netherlands Antilles +599',
          name_es: 'Antillas Holandesas +599'
        },
        {
          code: 'nc',
          dial_code: '+687',
          name_en: 'New Caledonia +687',
          name_es: 'Nueva Caledonia +687'
        },
        {
          code: 'nz',
          dial_code: '+64',
          name_en: 'New Zealand +64',
          name_es: 'Nueva Zelanda +64'
        },
        {
          code: 'ni',
          dial_code: '+505',
          name_en: 'Nicaragua +505',
          name_es: 'Nicaragua +505'
        },
        {
          code: 'ne',
          dial_code: '+227',
          name_en: 'Niger +227',
          name_es: 'Niger +227'
        },
        {
          code: 'ng',
          dial_code: '+234',
          name_en: 'Nigeria +234',
          name_es: 'Nigeria +234'
        },
        {
          code: 'nu',
          dial_code: '+683',
          name_en: 'Niue +683',
          name_es: 'Niue +683'
        },
        {
          code: 'nf',
          dial_code: '+672',
          name_en: 'NorfolkIsland +672',
          name_es: 'IslaNorfolk +672'
        },
        {
          code: 'mp',
          dial_code: '+1670',
          name_en: 'NorthernMarianaIslands +1670',
          name_es: 'IslasMarianasdelNorte +1670'
        },
        {
          code: 'no',
          dial_code: '+47',
          name_en: 'Norway +47',
          name_es: 'Noruega +47'
        },
        {
          code: 'om',
          dial_code: '+968',
          name_en: 'Oman +968',
          name_es: 'Omán +968'
        },
        {
          code: 'pk',
          dial_code: '+92',
          name_en: 'Pakistan +92',
          name_es: 'Pakistán +92'
        },
        {
          code: 'pw',
          dial_code: '+680',
          name_en: 'Palau +680',
          name_es: 'Palaos +680'
        },
        {
          code: 'pa',
          dial_code: '+507',
          name_en: 'Panama +507',
          name_es: 'Panamá +507'
        },
        {
          code: 'pg',
          dial_code: '+675',
          name_en: 'Papua New Guinea +675',
          name_es: 'Papúa Nueva Guinea +675'
        },
        {
          code: 'py',
          dial_code: '+595',
          name_en: 'Paraguay +595',
          name_es: 'Paraguay +595'
        },
        {
          code: 'pe',
          dial_code: '+51',
          name_en: 'Peru +51',
          name_es: 'Perú +51'
        },
        {
          code: 'ph',
          dial_code: '+63',
          name_en: 'Philippines +63',
          name_es: 'Filipinas +63'
        },
        {
          code: 'pn',
          dial_code: '+872',
          name_en: 'Pitcairn +872',
          name_es: 'Islas Pitcairn +872'
        },
        {
          code: 'pl',
          dial_code: '+48',
          name_en: 'Poland +48',
          name_es: 'Polonia +48'
        },
        {
          code: 'pt',
          dial_code: '+351',
          name_en: 'Portugal +351',
          name_es: 'Portugal +351'
        },
        {
          code: 'pr',
          dial_code: '+1939',
          name_en: 'Puerto Rico +1939',
          name_es: 'Puerto Rico +1939'
        },
        {
          code: 'qa',
          dial_code: '+974',
          name_en: 'Qatar +974',
          name_es: 'Qatar +974'
        },
        {
          code: 'ro',
          dial_code: '+40',
          name_en: 'Romania +40',
          name_es: 'Rumania +40'
        },
        {
          code: 'ru',
          dial_code: '+7',
          name_en: 'Russia +7',
          name_es: 'Rusia +7'
        },
        {
          code: 'rw',
          dial_code: '+250',
          name_en: 'Rwanda +250',
          name_es: 'Ruanda +250'
        },
        {
          code: 're',
          dial_code: '+262',
          name_en: 'Réunion +262',
          name_es: 'Reunion +262'
        },
        {
          code: 'bl',
          dial_code: '+590',
          name_en: 'Saint Barthélemy +590',
          name_es: 'San Bartolome +590'
        },
        {
          code: 'sh',
          dial_code: '+290',
          name_en: 'Saint Helena, Ascension and Tristan Da Cunha +290',
          name_es: 'Santa Elena, Ascensión y Tristán de Acuña +290'
        },
        {
          code: 'kn',
          dial_code: '+1869',
          name_en: 'Saint Kitts and Nevis +1869',
          name_es: 'San Cristóbal y Nieves +1869'
        },
        {
          code: 'lc',
          dial_code: '+1758',
          name_en: 'Saint Lucia +1758',
          name_es: 'Santa Lucía +1758'
        },
        {
          code: 'mf',
          dial_code: '+590',
          name_en: 'Saint Martin +590',
          name_es: 'Isla de San Martín +590'
        },
        {
          code: 'pm',
          dial_code: '+508',
          name_en: 'Saint Pierre and Miquelon +508',
          name_es: 'San Pedro y Miquelon +508'
        },
        {
          code: 'vc',
          dial_code: '+1784',
          name_en: 'Saint Vincent and the Grenadines +1784',
          name_es: 'San Vicente y las Granadinas +1784'
        },
        {
          code: 'ws',
          dial_code: '+685',
          name_en: 'Samoa +685',
          name_es: 'Samoa +685'
        },
        {
          code: 'sm',
          dial_code: '+378',
          name_en: 'San Marino +378',
          name_es: 'San Marino +378'
        },
        {
          code: 'st',
          dial_code: '+239',
          name_en: 'Sao Tome and Principe +239',
          name_es: 'Santo Tomé y Príncipe +239'
        },
        {
          code: 'sa',
          dial_code: '+966',
          name_en: 'Saudi Arabia +966',
          name_es: 'Arabia Saudita +966'
        },
        {
          code: 'sn',
          dial_code: '+221',
          name_en: 'Senegal +221',
          name_es: 'Senegal +221'
        },
        {
          code: 'rs',
          dial_code: '+381',
          name_en: 'Serbia +381',
          name_es: 'Serbia +381'
        },
        {
          code: 'sc',
          dial_code: '+248',
          name_en: 'Seychelles +248',
          name_es: 'Seychelles +248'
        },
        {
          code: 'sl',
          dial_code: '+232',
          name_en: 'Sierra Leone +232',
          name_es: 'Sierra Leona +232'
        },
        {
          code: 'sg',
          dial_code: '+65',
          name_en: 'Singapore +65',
          name_es: 'Singapur +65'
        },
        {
          code: 'sk',
          dial_code: '+421',
          name_en: 'Slovakia +421',
          name_es: 'Eslovaquia +421'
        },
        {
          code: 'si',
          dial_code: '+386',
          name_en: 'Slovenia +386',
          name_es: 'Eslovenia +386'
        },
        {
          code: 'sb',
          dial_code: '+677',
          name_en: 'Solomon Islands +677',
          name_es: 'Islas Salomón +677'
        },
        {
          code: 'so',
          dial_code: '+252',
          name_en: 'Somalia +252',
          name_es: 'Somalia +252'
        },
        {
          code: 'za',
          dial_code: '+27',
          name_en: 'South Africa +27',
          name_es: 'Sudáfrica +27'
        },
        {
          code: 'ss',
          dial_code: '+211',
          name_en: 'South Sudan +211',
          name_es: 'Sudán del Sur +211'
        },
        {
          code: 'es',
          dial_code: '+34',
          name_en: 'Spain +34',
          name_es: 'España +34'
        },
        {
          code: 'lk',
          dial_code: '+94',
          name_en: 'Sri Lanka +94',
          name_es: 'Sri Lanka +94'
        },
        {
          code: 'ps',
          dial_code: '+970',
          name_en: 'State of Palestine +970',
          name_es: 'Estado de Palestina +970'
        },
        {
          code: 'sd',
          dial_code: '+249',
          name_en: 'Sudan +249',
          name_es: 'Sudán +249'
        },
        {
          code: 'sr',
          dial_code: '+597',
          name_en: 'Suriname +597',
          name_es: 'Surinam +597'
        },
        {
          code: 'sj',
          dial_code: '+47',
          name_en: 'Svalbard and Jan Mayen +47',
          name_es: 'Svalbard y Jan Mayen +47'
        },
        {
          code: 'sz',
          dial_code: '+268',
          name_en: 'Swaziland +268',
          name_es: 'Suazilandia +268'
        },
        {
          code: 'se',
          dial_code: '+46',
          name_en: 'Sweden +46',
          name_es: 'Suecia +46'
        },
        {
          code: 'ch',
          dial_code: '+41',
          name_en: 'Switzerland +41',
          name_es: 'Suiza +41'
        },
        {
          code: 'sy',
          dial_code: '+963',
          name_en: 'Syrian Arab Republic +963',
          name_es: 'Siria +963'
        },
        {
          code: 'tw',
          dial_code: '+886',
          name_en: 'Taiwan, Province of China +886',
          name_es: 'Taiwán +886'
        },
        {
          code: 'tj',
          dial_code: '+992',
          name_en: 'Tayikistan +992',
          name_es: 'Tayikistán +992'
        },
        {
          code: 'tz',
          dial_code: '+255',
          name_en: 'Tanzania, United Republic of +255',
          name_es: 'Tanzania +255'
        },
        {
          code: 'th',
          dial_code: '+66',
          name_en: 'Thailand +66',
          name_es: 'Tailandia +66'
        },
        {
          code: 'tl',
          dial_code: '+670',
          name_en: 'Timor-Leste +670',
          name_es: 'Timor Oriental +670'
        },
        {
          code: 'tg',
          dial_code: '+228',
          name_en: 'Togo +228',
          name_es: 'Togo +228'
        },
        {
          code: 'tk',
          dial_code: '+690',
          name_en: 'Tokelau +690',
          name_es: 'Tokelau +690'
        },
        {
          code: 'to',
          dial_code: '+676',
          name_en: 'Tonga +676',
          name_es: 'Tonga +676'
        },
        {
          code: 'tt',
          dial_code: '+1868',
          name_en: 'Trinidad and Tobago +1868',
          name_es: 'Trinidad y Tobago +1868'
        },
        {
          code: 'tn',
          dial_code: '+216',
          name_en: 'Tunisia +216',
          name_es: 'Túnez +216'
        },
        {
          code: 'tr',
          dial_code: '+90',
          name_en: 'Turkey +90',
          name_es: 'Turquía +90'
        },
        {
          code: 'tm',
          dial_code: '+993',
          name_en: 'Turkmenistan +993',
          name_es: 'Turkmenistán +993'
        },
        {
          code: 'tc',
          dial_code: '+1649',
          name_en: 'Turks and Caicos Islands +1649',
          name_es: 'Islas Turcas y Caicos +1649'
        },
        {
          code: 'tv',
          dial_code: '+688',
          name_en: 'Tuvalu +688',
          name_es: 'Tuvalu +688'
        },
        {
          code: 'ug',
          dial_code: '+256',
          name_en: 'Uganda +256',
          name_es: 'Uganda +256'
        },
        {
          code: 'ua',
          dial_code: '+380',
          name_en: 'Ukraine +380',
          name_es: 'Ucrania +380'
        },
        {
          code: 'ae',
          dial_code: '+971',
          name_en: 'United Arab Emirates +971',
          name_es: 'Emiratos Árabes Unidos +971'
        },
        {
          code: 'gb',
          dial_code: '+44',
          name_en: 'United Kingdom +44',
          name_es: 'Reino Unido +44'
        },
        {
          code: 'us',
          dial_code: '+1',
          name_en: 'United States +1',
          name_es: 'Estados Unidos +1'
        },
        {
          code: 'uy',
          dial_code: '+598',
          name_en: 'Uruguay +598',
          name_es: 'Uruguay +598'
        },
        {
          code: 'uz',
          dial_code: '+998',
          name_en: 'Uzbekistan +998',
          name_es: 'Uzbekistán +998'
        },
        {
          code: 'vu',
          dial_code: '+678',
          name_en: 'Vanuatu +678',
          name_es: 'Vanuatu +678'
        },
        {
          code: 've',
          dial_code: '+58',
          name_en: 'Venezuela, Bolivarian Republic of +58',
          name_es: 'Venezuela +58'
        },
        {
          code: 'vn',
          dial_code: '+84',
          name_en: 'Vietnam +84',
          name_es: 'Vietnam +84'
        },
        {
          code: 'vg',
          dial_code: '+1284',
          name_en: 'Virgin Islands, British +1284',
          name_es: 'Islas Vírgenes Británicas +1284'
        },
        {
          code: 'vi',
          dial_code: '+1340',
          name_en: 'Virgin Islands, U.S. +1340',
          name_es: 'Islas Vírgenes de los Estados Unidos +1340'
        },
        {
          code: 'wf',
          dial_code: '+681',
          name_en: 'Wallis and Futuna +681',
          name_es: 'Wallis y Futuna +681'
        },
        {
          code: 'ye',
          dial_code: '+967',
          name_en: 'Yemen +967',
          name_es: 'Yemen +967'
        },
        {
          code: 'zm',
          dial_code: '+260',
          name_en: 'Zambia +260',
          name_es: 'Zambia +260'
        },
        {
          code: 'zw',
          dial_code: '+263',
          name_en: 'Zimbabwe +263',
          name_es: 'Zimbabue +263'
        },
        {
          code: 'ax',
          dial_code: '+358',
          name_en: 'Åland Islands +358',
          name_es: 'Åland +358'
        }
      ],
      countryCurrencies: [
        { alpha2: 'AC', currencies: ['USD'] },
        { alpha2: 'AD', currencies: ['EUR'] },
        { alpha2: 'AE', currencies: ['AED'] },
        { alpha2: 'AF', currencies: ['AFN'] },
        { alpha2: 'AG', currencies: ['XCD'] },
        { alpha2: 'AI', currencies: ['XCD'] },
        { alpha2: 'AI', currencies: [] },
        { alpha2: 'AL', currencies: ['ALL'] },
        { alpha2: 'AM', currencies: ['AMD'] },
        { alpha2: 'AN', currencies: [] },
        { alpha2: 'AO', currencies: ['AOA'] },
        { alpha2: 'AQ', currencies: [] },
        { alpha2: 'AR', currencies: ['ARS'] },
        { alpha2: 'AS', currencies: ['USD'] },
        { alpha2: 'AT', currencies: ['EUR'] },
        { alpha2: 'AU', currencies: ['AUD'] },
        { alpha2: 'AW', currencies: ['AWG'] },
        { alpha2: 'AX', currencies: ['EUR'] },
        { alpha2: 'AZ', currencies: ['AZN'] },
        { alpha2: 'BA', currencies: ['BAM'] },
        { alpha2: 'BB', currencies: ['BBD'] },
        { alpha2: 'BD', currencies: ['BDT'] },
        { alpha2: 'BE', currencies: ['EUR'] },
        { alpha2: 'BF', currencies: ['XOF'] },
        { alpha2: 'BG', currencies: ['BGN'] },
        { alpha2: 'BH', currencies: ['BHD'] },
        { alpha2: 'BI', currencies: ['BIF'] },
        { alpha2: 'BJ', currencies: ['XOF'] },
        { alpha2: 'BL', currencies: ['EUR'] },
        { alpha2: 'BM', currencies: ['BMD'] },
        { alpha2: 'BN', currencies: ['BND'] },
        { alpha2: 'BO', currencies: ['BOB', 'BOV'] },
        { alpha2: 'BQ', currencies: ['USD'] },
        { alpha2: 'BQ', currencies: [] },
        { alpha2: 'BR', currencies: ['BRL'] },
        { alpha2: 'BS', currencies: ['BSD'] },
        { alpha2: 'BT', currencies: ['INR', 'BTN'] },
        { alpha2: 'BU', currencies: [] },
        { alpha2: 'BV', currencies: ['NOK'] },
        { alpha2: 'BW', currencies: ['BWP'] },
        { alpha2: 'BY', currencies: ['BYR'] },
        { alpha2: 'BY', currencies: [] },
        { alpha2: 'BZ', currencies: ['BZD'] },
        { alpha2: 'CA', currencies: ['CAD'] },
        { alpha2: 'cc', currencies: ['AUD'] },
        { alpha2: 'CD', currencies: ['CDF'] },
        { alpha2: 'CF', currencies: ['XAF'] },
        { alpha2: 'CG', currencies: ['XAF'] },
        { alpha2: 'CH', currencies: ['CHF', 'CHE', 'CHW'] },
        { alpha2: 'CI', currencies: ['XOF'] },
        { alpha2: 'CK', currencies: ['NZD'] },
        { alpha2: 'CL', currencies: ['CLP', 'CLF'] },
        { alpha2: 'CM', currencies: ['XAF'] },
        { alpha2: 'CN', currencies: ['CNY'] },
        { alpha2: 'CO', currencies: ['COP', 'COU'] },
        { alpha2: 'CP', currencies: ['EUR'] },
        { alpha2: 'CR', currencies: ['CRC'] },
        { alpha2: 'CS', currencies: [] },
        { alpha2: 'CS', currencies: [] },
        { alpha2: 'CT', currencies: [] },
        { alpha2: 'CU', currencies: ['CUP', 'CUC'] },
        { alpha2: 'CV', currencies: ['CVE'] },
        { alpha2: 'CW', currencies: ['ANG'] },
        { alpha2: 'CX', currencies: ['AUD'] },
        { alpha2: 'CY', currencies: ['EUR'] },
        { alpha2: 'CZ', currencies: ['CZK'] },
        { alpha2: 'DD', currencies: [] },
        { alpha2: 'DE', currencies: ['EUR'] },
        { alpha2: 'DG', currencies: ['USD'] },
        { alpha2: 'DJ', currencies: ['DJF'] },
        { alpha2: 'DK', currencies: ['DKK'] },
        { alpha2: 'DM', currencies: ['XCD'] },
        { alpha2: 'DO', currencies: ['DOP'] },
        { alpha2: 'DY', currencies: [] },
        { alpha2: 'DZ', currencies: ['DZD'] },
        { alpha2: 'EA', currencies: ['EUR'] },
        { alpha2: 'EC', currencies: ['USD'] },
        { alpha2: 'EE', currencies: ['EUR'] },
        { alpha2: 'EG', currencies: ['EGP'] },
        { alpha2: 'EH', currencies: ['MAD'] },
        { alpha2: 'ER', currencies: ['ERN'] },
        { alpha2: 'ES', currencies: ['EUR'] },
        { alpha2: 'ET', currencies: ['ETB'] },
        { alpha2: 'EU', currencies: ['EUR'] },
        { alpha2: 'FI', currencies: ['EUR'] },
        { alpha2: 'FJ', currencies: ['FJD'] },
        { alpha2: 'FK', currencies: ['FKP'] },
        { alpha2: 'FM', currencies: ['USD'] },
        { alpha2: 'FO', currencies: ['DKK'] },
        { alpha2: 'FQ', currencies: [] },
        { alpha2: 'FR', currencies: ['EUR'] },
        { alpha2: 'FX', currencies: ['EUR'] },
        { alpha2: 'GA', currencies: ['XAF'] },
        { alpha2: 'GB', currencies: ['GBP'] },
        { alpha2: 'GD', currencies: ['XCD'] },
        { alpha2: 'GE', currencies: ['GEL'] },
        { alpha2: 'GE', currencies: [] },
        { alpha2: 'GF', currencies: ['EUR'] },
        { alpha2: 'GG', currencies: ['GBP'] },
        { alpha2: 'GH', currencies: ['GHS'] },
        { alpha2: 'GI', currencies: ['GIP'] },
        { alpha2: 'GL', currencies: ['DKK'] },
        { alpha2: 'GM', currencies: ['GMD'] },
        { alpha2: 'GN', currencies: ['GNF'] },
        { alpha2: 'GP', currencies: ['EUR'] },
        { alpha2: 'GQ', currencies: ['XAF'] },
        { alpha2: 'GR', currencies: ['EUR'] },
        { alpha2: 'GS', currencies: ['GBP'] },
        { alpha2: 'GT', currencies: ['GTQ'] },
        { alpha2: 'GU', currencies: ['USD'] },
        { alpha2: 'GW', currencies: ['XOF'] },
        { alpha2: 'GY', currencies: ['GYD'] },
        { alpha2: 'HK', currencies: ['HKD'] },
        { alpha2: 'HM', currencies: ['AUD'] },
        { alpha2: 'HN', currencies: ['HNL'] },
        { alpha2: 'HR', currencies: ['HRK'] },
        { alpha2: 'HT', currencies: ['HTG', 'USD'] },
        { alpha2: 'HU', currencies: ['HUF'] },
        { alpha2: 'HV', currencies: [] },
        { alpha2: 'IC', currencies: ['EUR'] },
        { alpha2: 'ID', currencies: ['IDR'] },
        { alpha2: 'IE', currencies: ['EUR'] },
        { alpha2: 'IL', currencies: ['ILS'] },
        { alpha2: 'IM', currencies: ['GBP'] },
        { alpha2: 'IN', currencies: ['INR'] },
        { alpha2: 'IO', currencies: ['USD'] },
        { alpha2: 'IQ', currencies: ['IQD'] },
        { alpha2: 'IR', currencies: ['IRR'] },
        { alpha2: 'IS', currencies: ['ISK'] },
        { alpha2: 'IT', currencies: ['EUR'] },
        { alpha2: 'JE', currencies: ['GBP'] },
        { alpha2: 'JM', currencies: ['JMD'] },
        { alpha2: 'JO', currencies: ['JOD'] },
        { alpha2: 'JP', currencies: ['JPY'] },
        { alpha2: 'JT', currencies: [] },
        { alpha2: 'KE', currencies: ['KES'] },
        { alpha2: 'KG', currencies: ['KGS'] },
        { alpha2: 'KH', currencies: ['KHR'] },
        { alpha2: 'KI', currencies: ['AUD'] },
        { alpha2: 'KM', currencies: ['KMF'] },
        { alpha2: 'KN', currencies: ['XCD'] },
        { alpha2: 'KP', currencies: ['KPW'] },
        { alpha2: 'KR', currencies: ['KRW'] },
        { alpha2: 'KW', currencies: ['KWD'] },
        { alpha2: 'KY', currencies: ['KYD'] },
        { alpha2: 'KZ', currencies: ['KZT'] },
        { alpha2: 'LA', currencies: ['LAK'] },
        { alpha2: 'LB', currencies: ['LBP'] },
        { alpha2: 'LC', currencies: ['XCD'] },
        { alpha2: 'LI', currencies: ['CHF'] },
        { alpha2: 'LK', currencies: ['LKR'] },
        { alpha2: 'LR', currencies: ['LRD'] },
        { alpha2: 'LS', currencies: ['LSL', 'ZAR'] },
        { alpha2: 'LT', currencies: ['EUR'] },
        { alpha2: 'LU', currencies: ['EUR'] },
        { alpha2: 'LV', currencies: ['EUR'] },
        { alpha2: 'LY', currencies: ['LYD'] },
        { alpha2: 'MA', currencies: ['MAD'] },
        { alpha2: 'MC', currencies: ['EUR'] },
        { alpha2: 'MD', currencies: ['MDL'] },
        { alpha2: 'ME', currencies: ['EUR'] },
        { alpha2: 'MF', currencies: ['EUR'] },
        { alpha2: 'MG', currencies: ['MGA'] },
        { alpha2: 'MH', currencies: ['USD'] },
        { alpha2: 'MI', currencies: [] },
        { alpha2: 'MK', currencies: ['MKD'] },
        { alpha2: 'ML', currencies: ['XOF'] },
        { alpha2: 'MM', currencies: ['MMK'] },
        { alpha2: 'MN', currencies: ['MNT'] },
        { alpha2: 'MO', currencies: ['MOP'] },
        { alpha2: 'MP', currencies: ['USD'] },
        { alpha2: 'MQ', currencies: ['EUR'] },
        { alpha2: 'MR', currencies: ['MRO'] },
        { alpha2: 'MS', currencies: ['XCD'] },
        { alpha2: 'MT', currencies: ['EUR'] },
        { alpha2: 'MU', currencies: ['MUR'] },
        { alpha2: 'MV', currencies: ['MVR'] },
        { alpha2: 'MW', currencies: ['MWK'] },
        { alpha2: 'MX', currencies: ['MXN', 'MXV'] },
        { alpha2: 'MY', currencies: ['MYR'] },
        { alpha2: 'MZ', currencies: ['MZN'] },
        { alpha2: 'NA', currencies: ['NAD', 'ZAR'] },
        { alpha2: 'NC', currencies: ['XPF'] },
        { alpha2: 'NE', currencies: ['XOF'] },
        { alpha2: 'NF', currencies: ['AUD'] },
        { alpha2: 'NG', currencies: ['NGN'] },
        { alpha2: 'NH', currencies: [] },
        { alpha2: 'NI', currencies: ['NIO'] },
        { alpha2: 'NL', currencies: ['EUR'] },
        { alpha2: 'NO', currencies: ['NOK'] },
        { alpha2: 'NP', currencies: ['NPR'] },
        { alpha2: 'NQ', currencies: [] },
        { alpha2: 'NR', currencies: ['AUD'] },
        { alpha2: 'NT', currencies: [] },
        { alpha2: 'NU', currencies: ['NZD'] },
        { alpha2: 'NZ', currencies: ['NZD'] },
        { alpha2: 'OM', currencies: ['OMR'] },
        { alpha2: 'PA', currencies: ['PAB', 'USD'] },
        { alpha2: 'PC', currencies: [] },
        { alpha2: 'PE', currencies: ['PEN'] },
        { alpha2: 'PF', currencies: ['XPF'] },
        { alpha2: 'PG', currencies: ['PGK'] },
        { alpha2: 'PH', currencies: ['PHP'] },
        { alpha2: 'PK', currencies: ['PKR'] },
        { alpha2: 'PL', currencies: ['PLN'] },
        { alpha2: 'PM', currencies: ['EUR'] },
        { alpha2: 'PN', currencies: ['NZD'] },
        { alpha2: 'PR', currencies: ['USD'] },
        { alpha2: 'PS', currencies: ['JOD', 'EGP', 'ILS'] },
        { alpha2: 'PT', currencies: ['EUR'] },
        { alpha2: 'PU', currencies: [] },
        { alpha2: 'PW', currencies: ['USD'] },
        { alpha2: 'PY', currencies: ['PYG'] },
        { alpha2: 'PZ', currencies: [] },
        { alpha2: 'QA', currencies: ['QAR'] },
        { alpha2: 'RE', currencies: ['EUR'] },
        { alpha2: 'RH', currencies: [] },
        { alpha2: 'RO', currencies: ['RON'] },
        { alpha2: 'RS', currencies: ['RSD'] },
        { alpha2: 'RU', currencies: ['RUB'] },
        { alpha2: 'RW', currencies: ['RWF'] },
        { alpha2: 'SA', currencies: ['SAR'] },
        { alpha2: 'SB', currencies: ['SBD'] },
        { alpha2: 'SC', currencies: ['SCR'] },
        { alpha2: 'SD', currencies: ['SDG'] },
        { alpha2: 'SE', currencies: ['SEK'] },
        { alpha2: 'SG', currencies: ['SGD'] },
        { alpha2: 'SH', currencies: ['SHP'] },
        { alpha2: 'SI', currencies: ['EUR'] },
        { alpha2: 'SJ', currencies: ['NOK'] },
        { alpha2: 'SK', currencies: ['EUR'] },
        { alpha2: 'SK', currencies: [] },
        { alpha2: 'SL', currencies: ['SLL'] },
        { alpha2: 'SM', currencies: ['EUR'] },
        { alpha2: 'SN', currencies: ['XOF'] },
        { alpha2: 'SO', currencies: ['SOS'] },
        { alpha2: 'SR', currencies: ['SRD'] },
        { alpha2: 'SS', currencies: ['SSP'] },
        { alpha2: 'ST', currencies: ['STD'] },
        { alpha2: 'SU', currencies: ['RUB'] },
        { alpha2: 'SV', currencies: ['USD'] },
        { alpha2: 'SX', currencies: ['ANG'] },
        { alpha2: 'SY', currencies: ['SYP'] },
        { alpha2: 'SZ', currencies: ['SZL'] },
        { alpha2: 'TA', currencies: ['GBP'] },
        { alpha2: 'TC', currencies: ['USD'] },
        { alpha2: 'TD', currencies: ['XAF'] },
        { alpha2: 'TF', currencies: ['EUR'] },
        { alpha2: 'TG', currencies: ['XOF'] },
        { alpha2: 'TH', currencies: ['THB'] },
        { alpha2: 'TJ', currencies: ['TJS'] },
        { alpha2: 'TK', currencies: ['NZD'] },
        { alpha2: 'TL', currencies: ['USD'] },
        { alpha2: 'TM', currencies: ['TMT'] },
        { alpha2: 'TN', currencies: ['TND'] },
        { alpha2: 'TO', currencies: ['TOP'] },
        { alpha2: 'TP', currencies: [] },
        { alpha2: 'TR', currencies: ['TRY'] },
        { alpha2: 'TT', currencies: ['TTD'] },
        { alpha2: 'TV', currencies: ['AUD'] },
        { alpha2: 'TW', currencies: ['TWD'] },
        { alpha2: 'TZ', currencies: ['TZS'] },
        { alpha2: 'UA', currencies: ['UAH'] },
        { alpha2: 'UG', currencies: ['UGX'] },
        { alpha2: 'UK', currencies: ['GBP'] },
        { alpha2: 'UM', currencies: ['USD'] },
        { alpha2: 'US', currencies: ['USD'] },
        { alpha2: 'UY', currencies: ['UYU', 'UYI'] },
        { alpha2: 'UZ', currencies: ['UZS'] },
        { alpha2: 'VA', currencies: ['EUR'] },
        { alpha2: 'VC', currencies: ['XCD'] },
        { alpha2: 'VD', currencies: [] },
        { alpha2: 'VE', currencies: ['VEF'] },
        { alpha2: 'VG', currencies: ['USD'] },
        { alpha2: 'VI', currencies: ['USD'] },
        { alpha2: 'VN', currencies: ['VND'] },
        { alpha2: 'VU', currencies: ['VUV'] },
        { alpha2: 'WF', currencies: ['XPF'] },
        { alpha2: 'WK', currencies: [] },
        { alpha2: 'WS', currencies: ['WST'] },
        { alpha2: 'XK', currencies: ['EUR'] },
        { alpha2: 'YD', currencies: [] },
        { alpha2: 'YE', currencies: ['YER'] },
        { alpha2: 'YT', currencies: ['EUR'] },
        { alpha2: 'YU', currencies: [] },
        { alpha2: 'ZA', currencies: ['ZAR'] },
        { alpha2: 'ZM', currencies: ['ZMW'] },
        { alpha2: 'ZR', currencies: [] },
        { alpha2: 'ZW', currencies: ['USD', 'ZAR', 'BWP', 'GBP', 'EUR'] }
      ],
      currencies: [
        {
          cod: 'AED',
          symbol: 'AED',
          name_en: 'UAE dirham',
          name_es: 'EAU dirham',
          symbolNative: 'د.إ.‏',
          decimalDigits: 2
        },
        {
          cod: 'AFN',
          symbol: 'Af',
          name_en: 'Afghan afghani',
          name_es: 'Afgano',
          symbolNative: '؋',
          decimalDigits: 0
        },
        {
          cod: 'ALL',
          symbol: 'ALL',
          name_en: 'Albanian lek',
          name_es: 'Lek albanés',
          symbolNative: 'Lek',
          decimalDigits: 0
        },
        {
          cod: 'AMD',
          symbol: 'AMD',
          name_en: 'Armenian dram',
          name_es: 'Dram armenio',
          symbolNative: 'դր.',
          decimalDigits: 0
        },
        {
          cod: 'ANG',
          symbol: 'NAƒ',
          name_en: 'Netherlands Antillean gulden',
          name_es: 'Países Bajos Antillanos Gulden',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'AOA',
          symbol: 'Kz',
          name_en: 'Angolan kwanza',
          name_es: 'Kwanza angoleña',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'ARS',
          symbol: 'AR$',
          name_en: 'Argentine peso',
          name_es: 'Peso argentino',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'AUD',
          symbol: 'AU$',
          name_en: 'Australian dollar',
          name_es: 'Dólar australiano',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'AWG',
          symbol: 'ƒ',
          name_en: 'Aruban florin',
          name_es: 'Florin de Aruba',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'AZN',
          symbol: 'man.',
          name_en: 'Azerbaijani manat',
          name_es: 'Azerbaiyani Manat',
          symbolNative: 'ман.',
          decimalDigits: 2
        },
        {
          cod: 'BAM',
          symbol: 'KM',
          name_en: 'Bosnia and Herzegovina konvertibilna marka',
          name_es: 'Bosnia y Herzegovina Konvertibilna Marka',
          symbolNative: 'KM',
          decimalDigits: 2
        },
        {
          cod: 'BBD',
          symbol: 'Bds$',
          name_en: 'Barbadian dollar',
          name_es: 'Dólar de Barbadia',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'BDT',
          symbol: 'Tk',
          name_en: 'Bangladeshi taka',
          name_es: 'Taka de Bangladesh',
          symbolNative: '৳',
          decimalDigits: 2
        },
        {
          cod: 'BGN',
          symbol: 'BGN',
          name_en: 'Bulgarian lev',
          name_es: 'Lev búlgaro',
          symbolNative: 'лв.',
          decimalDigits: 2
        },
        {
          cod: 'BHD',
          symbol: 'BD',
          name_en: 'Bahraini dinar',
          name_es: 'Dinar Bahreini',
          symbolNative: 'د.ب.‏',
          decimalDigits: 3
        },
        {
          cod: 'BIF',
          symbol: 'FBu',
          name_en: 'Burundi franc',
          name_es: 'Burundi Franc',
          symbolNative: 'FBu',
          decimalDigits: 0
        },
        {
          cod: 'BMD',
          symbol: 'BD$',
          name_en: 'Bermudian dollar',
          name_es: 'Dólar bermude',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'BND',
          symbol: 'BN$',
          name_en: 'Brunei dollar',
          name_es: 'Brunei dólar',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'BOB',
          symbol: 'Bs',
          name_en: 'Bolivian boliviano',
          name_es: 'Boliviano',
          symbolNative: 'Bs',
          decimalDigits: 2
        },
        {
          cod: 'BRL',
          symbol: 'R$',
          name_en: 'Brazilian real',
          name_es: 'Real brasileño',
          symbolNative: 'R$',
          decimalDigits: 2
        },
        {
          cod: 'BSD',
          symbol: 'B$',
          name_en: 'Bahamian dollar',
          name_es: 'Dólar bahamiano',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'BTN',
          symbol: 'Nu.',
          name_en: 'Bhutanese ngultrum',
          name_es: 'Ngultrum butanés',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'BWP',
          symbol: 'BWP',
          name_en: 'Botswana pula',
          name_es: 'Botswana Pula',
          symbolNative: 'P',
          decimalDigits: 2
        },
        {
          cod: 'BYR',
          symbol: 'Br',
          name_en: 'Belarusian ruble',
          name_es: 'Rublo bielorrusa',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'BZD',
          symbol: 'BZ$',
          name_en: 'Belize dollar',
          name_es: 'Dólar beliceño',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'CAD',
          symbol: 'CA$',
          name_en: 'Canadian dollar',
          name_es: 'Dólar canadiense',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'CDF',
          symbol: 'CDF',
          name_en: 'Congolese franc',
          name_es: 'Franco congoleño',
          symbolNative: 'FrCD',
          decimalDigits: 2
        },
        {
          cod: 'CHF',
          symbol: 'CHF',
          name_en: 'Swiss franc',
          name_es: 'Franco suizo',
          symbolNative: 'CHF',
          decimalDigits: 2
        },
        {
          cod: 'CLP',
          symbol: 'CL$',
          name_en: 'Chilean peso',
          name_es: 'Peso chileno',
          symbolNative: '$',
          decimalDigits: 0
        },
        {
          cod: 'CNY',
          symbol: 'CN¥',
          name_en: 'Chinese/Yuan renminbi',
          name_es: 'Renminbi chino/yuan',
          symbolNative: 'CN¥',
          decimalDigits: 2
        },
        {
          cod: 'COP',
          symbol: 'CO$',
          name_en: 'Colombian peso',
          name_es: 'Peso colombiano',
          symbolNative: '$',
          decimalDigits: 0
        },
        {
          cod: 'CRC',
          symbol: '₡',
          name_en: 'Costa Rican colon',
          name_es: 'Colon costarricense',
          symbolNative: '₡',
          decimalDigits: 0
        },
        {
          cod: 'CUC',
          symbol: '$',
          name_en: 'Cuban peso',
          name_es: 'Peso cubano',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'CVE',
          symbol: 'CV$',
          name_en: 'Cape Verdean escudo',
          name_es: 'Escudo caboverdiano',
          symbolNative: 'CV$',
          decimalDigits: 2
        },
        {
          cod: 'CZK',
          symbol: 'Kč',
          name_en: 'Czech koruna',
          name_es: 'Corona checa',
          symbolNative: 'Kč',
          decimalDigits: 2
        },
        {
          cod: 'DJF',
          symbol: 'Fdj',
          name_en: 'Djiboutian franc',
          name_es: 'Franco djibutiano',
          symbolNative: 'Fdj',
          decimalDigits: 0
        },
        {
          cod: 'DKK',
          symbol: 'Dkr',
          name_en: 'Danish krone',
          name_es: 'Corona danesa',
          symbolNative: 'kr',
          decimalDigits: 2
        },
        {
          cod: 'DOP',
          symbol: 'RD$',
          name_en: 'Dominican peso',
          name_es: 'Peso dominicano',
          symbolNative: 'RD$',
          decimalDigits: 2
        },
        {
          cod: 'DZD',
          symbol: 'DA',
          name_en: 'Algerian dinar',
          name_es: 'Dinar argelino',
          symbolNative: 'د.ج.‏',
          decimalDigits: 2
        },
        {
          cod: 'EEK',
          symbol: 'Ekr',
          name_en: 'Estonian kroon',
          name_es: 'Corona estonia',
          symbolNative: 'kr',
          decimalDigits: 2
        },
        {
          cod: 'EGP',
          symbol: 'EGP',
          name_en: 'Egyptian pound',
          name_es: 'Libra egipcia',
          symbolNative: 'ج.م.‏',
          decimalDigits: 2
        },
        {
          cod: 'ERN',
          symbol: 'Nfk',
          name_en: 'Eritrean nakfa',
          name_es: 'Nakfa eritrea',
          symbolNative: 'Nfk',
          decimalDigits: 2
        },
        {
          cod: 'ETB',
          symbol: 'Br',
          name_en: 'Ethiopian birr',
          name_es: 'Birr etíope',
          symbolNative: 'Br',
          decimalDigits: 2
        },
        {
          cod: 'EUR',
          symbol: '€',
          name_en: 'Euro',
          name_es: 'Euro',
          symbolNative: '€',
          decimalDigits: 2
        },
        {
          cod: 'FJD',
          symbol: 'FJ$',
          name_en: 'Fijian dollar',
          name_es: 'Dólar fiyiano',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'FKP',
          symbol: '£',
          name_en: 'Falkland Islands pound',
          name_es: 'Libra Islas Malvinas',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'GBP',
          symbol: '£',
          name_en: 'British pound',
          name_es: 'Libra británica',
          symbolNative: '£',
          decimalDigits: 2
        },
        {
          cod: 'GEL',
          symbol: 'GEL',
          name_en: 'Georgian lari',
          name_es: 'Georgiano Lari',
          symbolNative: 'GEL',
          decimalDigits: 2
        },
        {
          cod: 'GHS',
          symbol: 'GH₵',
          name_en: 'Ghanaian cedi',
          name_es: 'Cedi ghanés',
          symbolNative: 'GH₵',
          decimalDigits: 2
        },
        {
          cod: 'GIP',
          symbol: '£',
          name_en: 'Gibraltar pound',
          name_es: 'Libra de gibraltar',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'GMD',
          symbol: 'D',
          name_en: 'Gambian dalasi',
          name_es: 'Dalasi gambiano',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'GNF',
          symbol: 'FG',
          name_en: 'Guinean franc',
          name_es: 'Franco guineano',
          symbolNative: 'FG',
          decimalDigits: 0
        },
        {
          cod: 'GQE',
          symbol: 'CFA',
          name_en: 'Central African CFA franc',
          name_es: 'Franco CFA',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'GTQ',
          symbol: 'GTQ',
          name_en: 'Quetzal',
          name_es: 'Quetzal',
          symbolNative: 'Q',
          decimalDigits: 2
        },
        {
          cod: 'GYD',
          symbol: 'GY$',
          name_en: 'Guyanese dollar',
          name_es: 'Dólar guyanés',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'HKD',
          symbol: 'HK$',
          name_en: 'Hong Kong dollar',
          name_es: 'Dolar de Hong Kong',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'HNL',
          symbol: 'HNL',
          name_en: 'Honduran lempira',
          name_es: 'Lemppira hondureño',
          symbolNative: 'L',
          decimalDigits: 2
        },
        {
          cod: 'HRK',
          symbol: 'kn',
          name_en: 'Croatian kuna',
          name_es: 'Kuna croata',
          symbolNative: 'kn',
          decimalDigits: 2
        },
        {
          cod: 'HTG',
          symbol: 'G',
          name_en: 'Haitian gourde',
          name_es: 'Gourde haitiano',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'HUF',
          symbol: 'Ft',
          name_en: 'Hungarian forint',
          name_es: 'Forint húngaro',
          symbolNative: 'Ft',
          decimalDigits: 0
        },
        {
          cod: 'IDR',
          symbol: 'Rp',
          name_en: 'Indonesian rupiah',
          name_es: 'Rupia indonesia',
          symbolNative: 'Rp',
          decimalDigits: 0
        },
        {
          cod: 'ILS',
          symbol: '₪',
          name_en: 'Israeli new sheqel',
          name_es: 'Israelí Nuevo Sheqel',
          symbolNative: '₪',
          decimalDigits: 2
        },
        {
          cod: 'INR',
          symbol: 'Rs',
          name_en: 'Indian rupee',
          name_es: 'Rupia india',
          symbolNative: 'টকা',
          decimalDigits: 2
        },
        {
          cod: 'IQD',
          symbol: 'IQD',
          name_en: 'Iraqi dinar',
          name_es: 'Dinar iraquí',
          symbolNative: 'د.ع.‏',
          decimalDigits: 0
        },
        {
          cod: 'IRR',
          symbol: 'IRR',
          name_en: 'Iranian rial',
          name_es: 'Rial iraní',
          symbolNative: '﷼',
          decimalDigits: 0
        },
        {
          cod: 'ISK',
          symbol: 'Ikr',
          name_en: 'Icelandic krona',
          name_es: 'Corona islandesa',
          symbolNative: 'kr',
          decimalDigits: 0
        },
        {
          cod: 'JMD',
          symbol: 'J$',
          name_en: 'Jamaican dollar',
          name_es: 'Dólar jamaicano',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'JOD',
          symbol: 'JD',
          name_en: 'Jordanian dinar',
          name_es: 'Dinar jordano',
          symbolNative: 'د.أ.‏',
          decimalDigits: 3
        },
        {
          cod: 'JPY',
          symbol: '¥',
          name_en: 'Japanese yen',
          name_es: 'Yen japonés',
          symbolNative: '￥',
          decimalDigits: 0
        },
        {
          cod: 'KES',
          symbol: 'Ksh',
          name_en: 'Kenyan shilling',
          name_es: 'Kenia Shilling',
          symbolNative: 'Ksh',
          decimalDigits: 2
        },
        {
          cod: 'KGS',
          symbol: 'сом',
          name_en: 'Kyrgyzstani som',
          name_es: 'Kirguistáni som',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'KHR',
          symbol: 'KHR',
          name_en: 'Cambodian riel',
          name_es: 'Camboyo Riel',
          symbolNative: '៛',
          decimalDigits: 2
        },
        {
          cod: 'KMF',
          symbol: 'CF',
          name_en: 'Comorian franc',
          name_es: 'Comorista franco',
          symbolNative: 'FC',
          decimalDigits: 0
        },
        {
          cod: 'KPW',
          symbol: 'W',
          name_en: 'North Korean won',
          name_es: 'Ganó norcoreano',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'KRW',
          symbol: '₩',
          name_en: 'South Korean won',
          name_es: 'Won de Corea del Sur',
          symbolNative: '₩',
          decimalDigits: 0
        },
        {
          cod: 'KWD',
          symbol: 'KD',
          name_en: 'Kuwaiti dinar',
          name_es: 'Dinar kuwaití',
          symbolNative: 'د.ك.‏',
          decimalDigits: 3
        },
        {
          cod: 'KYD',
          symbol: 'KY$',
          name_en: 'Cayman Islands dollar',
          name_es: 'Dólar Islas de Caimán',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'KZT',
          symbol: 'KZT',
          name_en: 'Kazakhstani tenge',
          name_es: 'Kazajstani Tenge',
          symbolNative: 'тңг.',
          decimalDigits: 2
        },
        {
          cod: 'LAK',
          symbol: 'KN',
          name_en: 'Lao kip',
          name_es: 'Lao Kip',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'LBP',
          symbol: 'LB£',
          name_en: 'Lebanese lira',
          name_es: 'Lira libanesa',
          symbolNative: 'ل.ل.‏',
          decimalDigits: 0
        },
        {
          cod: 'LKR',
          symbol: 'SLRs',
          name_en: 'Sri Lankan rupee',
          name_es: 'Rupia de Sri Lanka',
          symbolNative: 'SL Re',
          decimalDigits: 2
        },
        {
          cod: 'LRD',
          symbol: 'L$',
          name_en: 'Liberian dollar',
          name_es: 'Dólar liberiano',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'LSL',
          symbol: 'M',
          name_en: 'Lesotho loti',
          name_es: 'Lesotho Loti',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'LTL',
          symbol: 'Lt',
          name_en: 'Lithuanian litas',
          name_es: 'Litas lituanas',
          symbolNative: 'Lt',
          decimalDigits: 2
        },
        {
          cod: 'LVL',
          symbol: 'Ls',
          name_en: 'Latvian lats',
          name_es: 'Latas letón',
          symbolNative: 'Ls',
          decimalDigits: 2
        },
        {
          cod: 'LYD',
          symbol: 'LD',
          name_en: 'Libyan dinar',
          name_es: 'Dinar libio',
          symbolNative: 'د.ل.‏',
          decimalDigits: 3
        },
        {
          cod: 'MAD',
          symbol: 'MAD',
          name_en: 'Moroccan dirham',
          name_es: 'Dirham marroquí',
          symbolNative: 'د.م.‏',
          decimalDigits: 2
        },
        {
          cod: 'MDL',
          symbol: 'MDL',
          name_en: 'Moldovan leu',
          name_es: 'Leu moldavo',
          symbolNative: 'MDL',
          decimalDigits: 2
        },
        {
          cod: 'MGA',
          symbol: 'MGA',
          name_en: 'Malagasy ariary',
          name_es: 'Ariario malgache',
          symbolNative: 'MGA',
          decimalDigits: 0
        },
        {
          cod: 'MKD',
          symbol: 'MKD',
          name_en: 'Macedonian denar',
          name_es: 'Denar macedonio',
          symbolNative: 'MKD',
          decimalDigits: 2
        },
        {
          cod: 'MMK',
          symbol: 'MMK',
          name_en: 'Myanma kyat',
          name_es: 'Myanma Kyat',
          symbolNative: 'K',
          decimalDigits: 0
        },
        {
          cod: 'MNT',
          symbol: '₮',
          name_en: 'Mongolian tugrik',
          name_es: 'Tugrik mongol',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'MOP',
          symbol: 'MOP$',
          name_en: 'Macanese pataca',
          name_es: 'Pataca macanesa',
          symbolNative: 'MOP$',
          decimalDigits: 2
        },
        {
          cod: 'MRO',
          symbol: 'UM',
          name_en: 'Mauritanian ouguiya',
          name_es: 'Mauritanio Ouguiya',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'MUR',
          symbol: 'MURs',
          name_en: 'Mauritian rupee',
          name_es: 'Rupia mauriciana',
          symbolNative: 'MURs',
          decimalDigits: 0
        },
        {
          cod: 'MVR',
          symbol: 'Rf',
          name_en: 'Maldivian rufiyaa',
          name_es: 'Rufiyaa de Maldivian',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'MWK',
          symbol: 'MK',
          name_en: 'Malawian kwacha',
          name_es: 'Kwacha de Malawi',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'MXN',
          symbol: 'MX$',
          name_en: 'Mexican peso',
          name_es: 'Peso mexicano',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'MYR',
          symbol: 'RM',
          name_en: 'Malaysian ringgit',
          name_es: 'Ringgit malayo',
          symbolNative: 'RM',
          decimalDigits: 2
        },
        {
          cod: 'MZM',
          symbol: 'MTn',
          name_en: 'Mozambican metical',
          name_es: 'Meticano Metambique',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'NAD',
          symbol: 'N$',
          name_en: 'Namibian dollar',
          name_es: 'Dólar namibio',
          symbolNative: 'N$',
          decimalDigits: 2
        },
        {
          cod: 'NGN',
          symbol: '₦',
          name_en: 'Nigerian naira',
          name_es: 'Naira nigeriana',
          symbolNative: '₦',
          decimalDigits: 2
        },
        {
          cod: 'NIO',
          symbol: 'C$',
          name_en: 'Nicaraguan Cordoba',
          name_es: 'Nicaragüan Córdoba',
          symbolNative: 'C$',
          decimalDigits: 2
        },
        {
          cod: 'NOK',
          symbol: 'Nkr',
          name_en: 'Norwegian krone',
          name_es: 'Krone noruego',
          symbolNative: 'kr',
          decimalDigits: 2
        },
        {
          cod: 'NPR',
          symbol: 'NPRs',
          name_en: 'Nepalese rupee',
          name_es: 'Rupia nepalí',
          symbolNative: 'नेरू',
          decimalDigits: 2
        },
        {
          cod: 'NZD',
          symbol: 'NZ$',
          name_en: 'New Zealand dollar',
          name_es: 'Dolar de Nueva Zelanda',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'OMR',
          symbol: 'OMR',
          name_en: 'Omani rial',
          name_es: 'Omaní rial',
          symbolNative: 'ر.ع.‏',
          decimalDigits: 3
        },
        {
          cod: 'PAB',
          symbol: 'B/.',
          name_en: 'Panamanian balboa',
          name_es: 'Balboa panameño',
          symbolNative: 'B/.',
          decimalDigits: 2
        },
        {
          cod: 'PEN',
          symbol: 'S/.',
          name_en: 'Peruvian nuevo sol',
          name_es: 'Nuevo Sol',
          symbolNative: 'S/.',
          decimalDigits: 2
        },
        {
          cod: 'PGK',
          symbol: 'K',
          name_en: 'Papua New Guinean kina',
          name_es: 'Papua Nueva Kina guineana',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'PHP',
          symbol: '₱',
          name_en: 'Philippine peso',
          name_es: 'Peso filipino',
          symbolNative: '₱',
          decimalDigits: 2
        },
        {
          cod: 'PKR',
          symbol: 'PKRs',
          name_en: 'Pakistani rupee',
          name_es: 'Rupia paquistaní',
          symbolNative: '₨',
          decimalDigits: 0
        },
        {
          cod: 'PLN',
          symbol: 'zł',
          name_en: 'Polish zloty',
          name_es: 'Zloty polaco',
          symbolNative: 'zł',
          decimalDigits: 2
        },
        {
          cod: 'PYG',
          symbol: '₲',
          name_en: 'Paraguayan guarani',
          name_es: 'Guarani',
          symbolNative: '₲',
          decimalDigits: 0
        },
        {
          cod: 'QAR',
          symbol: 'QR',
          name_en: 'Qatari riyal',
          name_es: 'Qatari riyal',
          symbolNative: 'ر.ق.‏',
          decimalDigits: 2
        },
        {
          cod: 'RON',
          symbol: 'RON',
          name_en: 'Romanian leu',
          name_es: 'Leu rumano',
          symbolNative: 'RON',
          decimalDigits: 2
        },
        {
          cod: 'RSD',
          symbol: 'din.',
          name_en: 'Serbian dinar',
          name_es: 'Dinar serbio',
          symbolNative: 'дин.',
          decimalDigits: 0
        },
        {
          cod: 'RUB',
          symbol: 'RUB',
          name_en: 'Russian ruble',
          name_es: 'Rublo ruso',
          symbolNative: '₽.',
          decimalDigits: 2
        },
        {
          cod: 'SAR',
          symbol: 'SR',
          name_en: 'Saudi riyal',
          name_es: 'Saudí riyal',
          symbolNative: 'ر.س.‏',
          decimalDigits: 2
        },
        {
          cod: 'SBD',
          symbol: 'SI$',
          name_en: 'Solomon Islands dollar',
          name_es: 'Dólar Islas Salomón',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'SCR',
          symbol: 'SR',
          name_en: 'Seychellois rupee',
          name_es: 'Seychellois rupia',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'SDG',
          symbol: 'SDG',
          name_en: 'Sudanese pound',
          name_es: 'Libra sudanesa',
          symbolNative: 'SDG',
          decimalDigits: 2
        },
        {
          cod: 'SEK',
          symbol: 'Skr',
          name_en: 'Swedish krona',
          name_es: 'Corona sueca',
          symbolNative: 'kr',
          decimalDigits: 2
        },
        {
          cod: 'SGD',
          symbol: 'S$',
          name_en: 'Singapore dollar',
          name_es: 'Dolar de Singapur',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'SHP',
          symbol: '£',
          name_en: 'Saint Helena pound',
          name_es: 'Libra Santa Helena',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'SLL',
          symbol: 'Le',
          name_en: 'Sierra Leonean leone',
          name_es: 'Leona',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'SOS',
          symbol: 'Ssh',
          name_en: 'Somali shilling',
          name_es: 'Chelín somalí',
          symbolNative: 'Ssh',
          decimalDigits: 0
        },
        {
          cod: 'SRD',
          symbol: '$',
          name_en: 'Surinamese dollar',
          name_es: 'Dolar surinamés',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'SYP',
          symbol: 'SY£',
          name_en: 'Syrian pound',
          name_es: 'Libra siria',
          symbolNative: 'ل.س.‏',
          decimalDigits: 0
        },
        {
          cod: 'SZL',
          symbol: 'E',
          name_en: 'Swazi lilangeni',
          name_es: 'Swazi Lilangeni',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'THB',
          symbol: '฿',
          name_en: 'Thai baht',
          name_es: 'Baht tailandés',
          symbolNative: '฿',
          decimalDigits: 2
        },
        {
          cod: 'TJS',
          symbol: 'TJS',
          name_en: 'Tajikistani somoni',
          name_es: 'Tayikistaní somoni',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'TMT',
          symbol: 'm',
          name_en: 'Turkmen manat',
          name_es: 'Turkmen Manat',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'TND',
          symbol: 'DT',
          name_en: 'Tunisian dinar',
          name_es: 'Dinar tunecino',
          symbolNative: 'د.ت.‏',
          decimalDigits: 3
        },
        {
          cod: 'TRY',
          symbol: 'TL',
          name_en: 'Turkish new lira',
          name_es: 'Turca nueva lira',
          symbolNative: 'TL',
          decimalDigits: 2
        },
        {
          cod: 'TTD',
          symbol: 'TT$',
          name_en: 'Trinidad and Tobago dollar',
          name_es: 'Dolar Trinidad y tobago',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'TWD',
          symbol: 'NT$',
          name_en: 'New Taiwan dollar',
          name_es: 'Nuevo dólar de Taiwán',
          symbolNative: 'NT$',
          decimalDigits: 2
        },
        {
          cod: 'TZS',
          symbol: 'TSh',
          name_en: 'Tanzanian shilling',
          name_es: 'Chelín tanzano',
          symbolNative: 'TSh',
          decimalDigits: 0
        },
        {
          cod: 'UAH',
          symbol: '₴',
          name_en: 'Ukrainian hryvnia',
          name_es: 'Hryvnia ucraniano',
          symbolNative: '₴',
          decimalDigits: 2
        },
        {
          cod: 'UGX',
          symbol: 'USh',
          name_en: 'Ugandan shilling',
          name_es: 'Chelín ugandés',
          symbolNative: 'USh',
          decimalDigits: 0
        },
        {
          cod: 'USD',
          symbol: '$',
          name_en: 'United States dollar',
          name_es: 'Dólar US',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'UYU',
          symbol: '$U',
          name_en: 'Uruguayan peso',
          name_es: 'Peso uruguayo',
          symbolNative: '$',
          decimalDigits: 2
        },
        {
          cod: 'UZS',
          symbol: 'UZS',
          name_en: 'Uzbekistani som',
          name_es: 'Som uzbeko',
          symbolNative: 'UZS',
          decimalDigits: 0
        },
        {
          cod: 'VEB',
          symbol: 'Bs',
          name_en: 'Venezuelan bolivar',
          name_es: 'Bolívar',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'VND',
          symbol: '₫',
          name_en: 'Vietnamese dong',
          name_es: 'Dong vietnamita',
          symbolNative: '₫',
          decimalDigits: 0
        },
        {
          cod: 'VUV',
          symbol: 'VT',
          name_en: 'Vanuatu vatu',
          name_es: 'Vanuatu vatu',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'WST',
          symbol: 'WS$',
          name_en: 'Samoan tala',
          name_es: 'Samoa Tala',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'XAF',
          symbol: 'FCFA',
          name_en: 'Central African CFA franc',
          name_es: 'Franco CFA Central',
          symbolNative: 'FCFA',
          decimalDigits: 0
        },
        {
          cod: 'XCD',
          symbol: 'EC$',
          name_en: 'East Caribbean dollar',
          name_es: 'Dólar caribeño del este',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'XOF',
          symbol: 'CFA',
          name_en: 'West African CFA franc',
          name_es: 'Franco CFA Occidental',
          symbolNative: 'CFA',
          decimalDigits: 0
        },
        {
          cod: 'XPF',
          symbol: 'F',
          name_en: 'CFP franc',
          name_es: 'Franco de CFP',
          symbolNative: '',
          decimalDigits: 0
        },
        {
          cod: 'YER',
          symbol: 'YR',
          name_en: 'Yemeni rial',
          name_es: 'Yemení rial',
          symbolNative: 'ر.ي.‏',
          decimalDigits: 0
        },
        {
          cod: 'ZAR',
          symbol: 'R',
          name_en: 'South African rand',
          name_es: 'Rand sudafricano',
          symbolNative: 'R',
          decimalDigits: 2
        },
        {
          cod: 'ZMK',
          symbol: 'ZK',
          name_en: 'Zambian kwacha',
          name_es: 'Kwacha zambiano',
          symbolNative: 'ZK',
          decimalDigits: 0
        },
        {
          cod: 'ZWR',
          symbol: 'Z$',
          name_en: 'Zimbabwean dollar',
          name_es: 'Dólar zimbabuense',
          symbolNative: '',
          decimalDigits: 0
        }
      ],
      todo: [
        { alpha2: 'DO', countryCallingCodes: ['+1 809', '+1 829', '+1 849'] },
        { alpha2: 'KZ', countryCallingCodes: ['+7', '+7 6', '+7 7'] },
        { alpha2: 'PR', countryCallingCodes: ['+1 787', '+1 939'] },
        { alpha2: 'RU', countryCallingCodes: ['+7', '+7 3', '+7 4', '+7 8'] },
        { alpha2: 'VA', countryCallingCodes: ['+379', '+39'] }
      ]
    }
  },
  getters: {
    clientInfo () {
      return {
        nan: navigator.appName, // navigatorAppName
        nac: navigator.appCodeName, // navigatorAppCodeName
        nav: navigator.appVersion, // navigatorappVersion
        npl: navigator.platform, // navigatorPlatform
        npr: navigator.product, // navigatorProduct
        nua: navigator.userAgent, // navigatorUserAgent
        nla: navigator.language, // navigatorLanguage
        nol: navigator.onLine, // navigatorOnLine
        // nje: navigator.javaEnabled(), // navigatorJavaEnabled
        wih: window.innerHeight, // wihndowInnerHeight
        wiw: window.innerWidth // windowInnerWidth
      }
    },
    clientLocation (state) {
      return state.clientLocation
    },
    currenciesFromCountryCode: (state) => (countryCode) => {
      if (countryCode === '') return []

      const code = countryCode.toUpperCase()
      const index = state.countryCurrencies.findIndex(ccInfo => {
        return ccInfo.alpha2 === code
      })

      const { currencies } = index > -1
        ? state.countryCurrencies[index]
        : []

      const data = state.currencies.filter(currency => {
        return currencies.indexOf(currency.cod) > -1
      })

      return data.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    },
    phonePrefix (state) {
      return state.phonePrefix.sort((a, b) => {
        if (a.name_es > b.name_es) return 1
        if (a.name_es < b.name_es) return -1
        return 0
      })
    }
  },
  mutations: {
    clientLocation (state, location) {
      Object.keys(location).forEach(key => {
        state.clientLocation[key] = location[key]
      })
      this.$local.set('app/helpers/clientLocation', location)
    }
  },
  actions: {
    getClientLocation ({ commit }) {
      return new Promise(resolve => {
        const location = this.$local.get('app/helpers/clientLocation')

        if (location) {
          commit('clientLocation', location)
          commit('setParams', {
            codPais: location.countryCode
          }, { root: true })
          resolve()
        } else {
          // https://www.bigdatacloud.com/docs/api/free-reverse-geocode-to-city-api
          fetch('https://api.bigdatacloud.net/data/reverse-geocode-client?localityLanguage=es')
            .then(response => {
              return response.json()
            })
            .then(json => {
              json.countryCode = json.countryCode.toLowerCase()
              commit('clientLocation', json)
              commit('setParams', {
                codPais: json.countryCode
              }, { root: true })
              resolve(json)
            })
            .catch(() => {
              resolve()
            })
        }
      })
    }
  }
}

export default helpers
