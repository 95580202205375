import { addDoc, collection, Timestamp } from 'firebase/firestore'
import { db } from '../../../plugins/fbase'

const paypal = {
  namespaced: true,
  state () {
    return {
      params: {
        production: {
          env: ' production',
          hosted_button_id: 'BJ3ZBX9M5EUH6',
          src: 'https://pics.paypal.com/00/s/NGQ2MTEzOWUtNGI5OS00ZDlhLThlNDMtZTgzMjg5YmFkZGMx/file.PNG'
        },
        sandbox: {
          env: ' sandbox',
          hosted_button_id: 'U8DR6U3Z8FPAU',
          src: 'https://pics-v2.sandbox.paypal.com/00/s/MzhkNjdkMDQtYjBmZS00NGJhLTkwYzYtNzJiN2NkMTYxMGQ0/file.PNG'
        }
      },
      payPalCurrencies: [
        'AUD',
        'BRL',
        'CAD',
        'CZK',
        'DKK',
        'EUR',
        'HKD',
        'HUF',
        'ILS',
        'JPY',
        'MYR',
        'MXN',
        'TWD',
        'NZD',
        'NOK',
        'PHP',
        'PLN',
        'GBP',
        'RUB',
        'SGD',
        'SEK',
        'CHF',
        'THB',
        'USD'
      ],
      scriptReady: false
    }
  },
  actions: {
    loadDonationScript ({ state }) {
      if (state.scriptReady) return true

      const payPalScript = document.createElement('script')
      payPalScript.setAttribute('src', 'https://www.paypalobjects.com/donate/sdk/donate-sdk.js')
      payPalScript.setAttribute('charset', 'UTF-8')
      payPalScript.setAttribute('async', true)
      document.head.appendChild(payPalScript)

      state.scriptReady = true
      return true
    },
    // https://developer.paypal.com/sdk/donate/#link-test
    registrarDonacion ({ rootGetters, dispatch }, params) {
      return new Promise((resolve, reject) => {
        const { current } = this.$router.history
        const formDatosContacto = rootGetters['app/user/contacto/formContacto']
        // const formMensaje = rootGetters['sac/formMensaje']
        // const formDonacion = rootGetters['donaciones/formDonacion']

        const newDonacion = {
          assignedTo: {
            user: '',
            sede: ''
          },
          contactInfo: { ...formDatosContacto },
          // donation: { ...formDonacion },
          donationPayPal: { ...params },
          message: '',
          route: {
            name: current.name,
            params: current.params
          },
          status: {
            user: 1,
            sacUser: 0
          },
          tm: {
            closed: '',
            created: Timestamp.fromDate(new Date()),
            firstA: ''
          }
        }

        console.log(newDonacion)

        addDoc(collection(db, 'donaciones'), newDonacion)
          .then(doc => {
            resolve()
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  }
}

export default paypal
