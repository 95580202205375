// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'

const logros = {
  namespaced: true,
  state: () => ({
    items: [
      {
        id: 1,
        text: '32 años evangelizando.',
        icon: 'fa-heart'
      },
      {
        id: 2,
        text: '14 Casas de la Misericordia en 7 países.',
        icon: 'fa-house-flag'
      },
      {
        id: 3,
        text: '3 Casas de ayuda a personas con problemas de adicción.',
        icon: 'fa-people-roof' // fa-house-user fa-solid fa-people-roof
      },
      {
        id: 4,
        text: '2 Casas de retiros en Colombia y Panamá.',
        icon: 'fa-house-fire'
      },
      {
        id: 5,
        text: '3 Hogares de paso para familiares de pacientes en crisis.',
        icon: 'fa-bed'
      },
      {
        id: 6,
        text: '60 Horas de Oración y Evangelización por Youtube cada semana.',
        icon: 'fa-circle-play'
      },
      {
        id: 7,
        text: '120 Horas de Oración y Reparación por Youtube cada mes.',
        icon: 'fa-person-praying'
      },
      {
        id: 8,
        text: 'Acompañamiento a mujeres con embarazo en crisis.',
        icon: 'fa-baby-carriage' // fa-person-breastfeeding | fa-baby-carriage
      },
      {
        id: 9,
        text: 'Formación constante a jóvenes Misioneros de la Misericordia.',
        icon: 'fa-book-open-reader'
      },
      {
        id: 10,
        text: 'Misioneros llevando el Amor del Padre en 9 países.',
        icon: 'fa-person-harassing'
      },
      {
        id: 11,
        text: 'Niños atendidos en comedores infantiles.',
        icon: 'fa-carrot'
      },
      {
        id: 12,
        text: 'Privados de la libertad orando con Misericordia día a día.',
        icon: 'fa-hands-praying'
      }
    ]
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (id) => {
      const index = state.items.findIndex(item => {
        return item.id === id
      })
      return index > -1
        ? state.items[index]
        : {} // TODO: default
    }
  },
  actions: {},
  mutations: {},
  modules: {}
}

export default logros
