// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'

const ubicaciones = {
  namespaced: true,
  state: () => ({
    items: []
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (id) => {
      const index = state.items.findIndex(item => {
        return item.id === id
      })
      return index > -1
        ? state.items[index]
        : {} // TODO: default
    }
  },
  actions: {},
  mutations: {},
  modules: {}
}

export default ubicaciones
