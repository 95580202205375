// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'

const metas = {
  namespaced: true,
  state: () => ({
    items: [
      {
        id: '1-actualizar-equipos',
        text: 'Actualizar nuestros equipos de comunicaciones.',
        desc: 'Algunos de nuestros equipos cumplieron ya 10 años con nosotros; no queremos reemplazarlos por la última tecnología, más bien deseamos comprar lo necesario para seguir produciendo buen contenido para inundar las redes sociales de la Misericordia de Dios. <b>¡Te animas!</b>',
        redes: 'Algunos de nuestros equipos cumplieron ya 10 años con nosotros; no queremos reemplazarlos por la última tecnología, más bien deseamos comprar lo necesario para seguir produciendo buen contenido para inundar las redes sociales de la Misericordia de Dios. ¡Te animas!',
        icon: 'fa-check-circle',
        img: '/imgs/camp-22/metas/meta-01.png',
        img2: '/imgs/camp-22/metas/meta-01-comunidad.jpg',
        anterior: '3-sostener-misioneros',
        siguiente: '2-restaurar-capilla-san-juan-pablo-ii',
        to: 'meta/1'
      },
      {
        id: '2-restaurar-capilla-san-juan-pablo-ii',
        text: 'Restaurar la capilla en la Finca san Juan Pablo II.',
        desc: 'En nuestra finca vivimos momentos muy especiales: la usamos como lugar de retiro, espacio de formación, también pasan tiempo algunos de los chicos que luchan contra una adicción, evangelizamos a la comunidad y queremos que nuestro Señor tenga una lugar digno donde adorarlo en el Santísimo Sacramento.<br><b>Te animas a ayudarnos.</b>',
        redes: 'En nuestra finca vivimos momentos muy especiales: la usamos como lugar de retiro, espacio de formación, también pasan tiempo algunos de los chicos que luchan contra una adicción, evangelizamos a la comunidad y queremos que nuestro Señor tenga una lugar digno donde adorarlo en el Santísimo Sacramento. Te animas a ayudarnos.',
        icon: 'fa-check-circle',
        img: '/imgs/camp-22/metas/meta-02.png',
        img2: '/imgs/camp-22/metas/meta-02-comunidad.jpg',
        anterior: '1-actualizar-equipos',
        siguiente: '3-sostener-misioneros',
        to: '/meta/2'
      },
      {
        id: '3-sostener-misioneros',
        text: 'Sostener el trabajo que realizan los misioneros.',
        desc: 'Dijo alguna vez la madre santa Teresa de Calcuta: <i>"Si no puedes alimentar a un centenar de personas, alimenta a una sola".</i> Lo mismo aplica para quienes tienen hambre de Dios. <b>Te animas,</b> tu apoyo económico sostiene el trabajo de nuestros misioneros.',
        redes: 'Dijo alguna vez la madre santa Teresa de Calcuta: "Si no puedes alimentar a un centenar de personas, alimenta a una sola". Lo mismo aplica para quienes tienen hambre de Dios. Te animas, tu apoyo económico sostiene el trabajo de nuestros misioneros.',
        icon: 'fa-check-circle',
        img: '/imgs/camp-22/metas/meta-03-v1.png',
        img2: '/imgs/camp-22/metas/meta-03-comunidad.jpg',
        anterior: '2-restaurar-capilla-san-juan-pablo-ii',
        siguiente: '1-actualizar-equipos',
        to: '/meta/3'
      }
    ]
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (id) => {
      const index = state.items.findIndex(item => {
        return item.id === id
      })
      return index > -1
        ? state.items[index]
        : {
            id: 0,
            text: '',
            icon: 'fa-check-circle',
            img: '/imgs/camp-22/metas/meta-01.png',
            to: ''
          }
    }
  },
  actions: {},
  mutations: {},
  modules: {}
}

export default metas
