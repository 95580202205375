// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'
import efectivo from '../../integraciones/efectivo/efectivo'
import paypal from '../../integraciones/paypal/paypal'

const canales = {
  namespaced: true,
  state: () => ({
    items: [
      { // whatsapp
        activo: true,
        id: 'whatsapp',
        name: 'Whatsapp',
        paises: {},
        mundo: true,
        src: '/imgs/canales/whatsapp.jpg'
      },
      { // efectivo
        activo: true,
        id: 'efectivo',
        name: 'Efectivo',
        paises: {
          co: true,
          pa: true
        },
        mundo: false,
        src: '/imgs/canales/efectivo.jpg'
      },
      { // daviplata
        activo: true,
        id: 'daviplata',
        name: 'Daviplata',
        paises: {
          co: true
        },
        mundo: false,
        src: '/imgs/canales/daviplata.jpg'
      },
      { // nequi
        activo: true,
        id: 'nequi',
        name: 'Nequi',
        paises: {
          co: true
        },
        mundo: false,
        src: '/imgs/canales/nequi.jpg'
      },
      { // yappy
        activo: false,
        id: 'yappy',
        name: 'Yappy',
        paises: {
          pa: true
        },
        mundo: false,
        src: '/imgs/canales/yappy.jpg'
      },
      { // wompi
        activo: false,
        id: 'wompi',
        name: 'Wompi',
        paises: {},
        mundo: true,
        src: '/imgs/canales/wompi.jpg'
      },
      { // paypal
        activo: true,
        id: 'paypal',
        name: 'Paypal',
        paises: {},
        mundo: true,
        src: '/imgs/canales/paypal.jpg'
      },
      { // patreon
        activo: false,
        id: 'patreon',
        name: 'Patreon',
        paises: {},
        mundo: true,
        src: '/imgs/canales/patreon.jpg'
      },
      { // payu
        activo: false,
        id: 'payu',
        name: 'Payu',
        paises: {},
        mundo: true,
        src: '/imgs/canales/payu.jpg'
      },
      { // bancolombia
        activo: true,
        id: 'bancolombia',
        name: 'Bancolombia',
        paises: { co: true },
        mundo: false,
        src: '/imgs/canales/bancolombia.jpg'
      },
      { // bancoGeneral
        activo: true,
        id: 'bancoGeneral',
        name: 'Banco General',
        paises: {
          pa: true
        },
        mundo: false,
        src: '/imgs/canales/banco-general.jpg'
      },
      { // bankOfAmerica
        activo: false,
        id: 'bankOfAmerica',
        name: 'Bank of America',
        paises: {
          us: true
        },
        mundo: false,
        src: '/imgs/canales/bank-of-america.jpg'
      }
    ]
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (id) => {
      const index = state.items.findIndex(item => {
        return item.id === id
      })
      return index > -1
        ? state.items[index]
        : {} // TODO: default
    },
    activos (state) {
      return state.items.filter(item => {
        return item.activo === true
      })
    },
    byPaisSelected (state, getters, rootState, rootGetters) {
      // pais elegido
      const { codPais } = rootGetters._params

      // mundo
      if (codPais === 'mundo') {
        return getters.activos.filter(item => {
          return item.mundo
        })
      }

      return getters.activos.filter(item => {
        return item.mundo ||
          item.paises[codPais] !== undefined
      })
    },
    selected (state, getters, rootState, rootGetters) {
      // canal selected
      const { canalId } = rootGetters._params

      const index = state.items.findIndex(canal => {
        return canal.id === canalId
      })
      return index > -1
        ? state.items[index]
        : {
            activo: false,
            id: '',
            name: '',
            paises: {},
            mundo: true,
            src: '/imgs/canales/_white.jpg'
          }
    }
  },
  actions: {},
  mutations: {},
  modules: {
    efectivo,
    paypal
  }
}

export default canales
