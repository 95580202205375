import Vue from 'vue'
import Vuex from 'vuex'
import VuexORM from '@vuex-orm/core'
// modulos
import app from '../app/app'
import org from '../domain/org/org'
import campanias from '../domain/campanias/campanias'
import comunidad from '../domain/comunidad/comunidad'
import recaudos from '../domain/recaudos/recaudos'
import sac from '../domain/sac/sac'

// Models
import models from '@/models/_index'
import repository from '@/models/_repository'

Vue.use(Vuex)

const db = new VuexORM.Database()

models.forEach(model => {
  db.register(model, repository)
})

const store = new Vuex.Store({
  plugins: [VuexORM.install(db, { namespace: '_local' })],
  state: () => ({
    params: {
      canalId: '',
      codPais: '',
      idAlcance: '',
      landingId: 'dia-dia',
      tipoAlcance: ''
    },
    client: {
      windowH: 0,
      windowW: 0
    }
  }),
  getters: {
    _params (state) {
      return state.params
    },
    _client (state) {
      return state.client
    }
  },
  mutations: {
    setClient (state, client) {
      Object.keys(client).forEach(key => {
        if (state.client[key] !== undefined) {
          state.client[key] = client[key]
        }
      })
    },
    setParams (state, params) {
      Object.keys(params).forEach(key => {
        if (state.params[key] !== undefined) {
          state.params[key] = params[key]
        }
      })
    }
  },
  modules: {
    app,
    campanias,
    comunidad,
    org,
    recaudos,
    sac
  }
})

export default store
