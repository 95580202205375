// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'
import { Timestamp } from 'firebase/firestore'

const types = {
  namespaced: true,
  state: () => ({
    items: [
      {
        id: 'donaciones',
        version: 1,
        icon: 'fa-hand-holding-dollar',
        title: 'Donaciones',
        moduleIds: ['campanias'],
        defaultFilter: 'pendientes',
        actions: [
          {
            id: 'new-item',
            name: 'Crear',
            basic: true,
            icon: '',
            type: '',
            subtype: '',
            showIn: {
              itemList: false,
              itemViewBar: false,
              workSpaceBar: true
            }
          },
          {
            id: 'view-item',
            name: 'Ver',
            basic: true,
            icon: '',
            type: '',
            subtype: '',
            showIn: {
              itemList: false,
              itemViewBar: false,
              workSpaceBar: false
            }
          },
          {
            id: 'edit-item',
            name: 'Editar',
            basic: true,
            icon: '',
            type: '',
            subtype: '',
            showIn: {
              itemList: true,
              itemViewBar: false,
              workSpaceBar: true
            }
          },
          {
            id: 'delete-item',
            name: 'Eliminar',
            basic: true,
            icon: '',
            type: '',
            subtype: '',
            showIn: {
              itemList: true,
              itemViewBar: false,
              workSpaceBar: true
            }
          }
        ],
        filters: [
          {
            id: 'pendientes',
            title: 'Pendientes',
            icon: 'fa-regular fa-bell',
            moduleId: 'campanias',
            typeId: 'donaciones',
            collectionId: '',
            params: {}
          },
          {
            id: 'enCurso',
            title: 'En curso',
            icon: 'fa-list-check', // <i class="fa-solid fa-list-check"></i>
            moduleId: 'campanias',
            typeId: 'donaciones',
            collectionId: '',
            params: {}
          },
          {
            id: 'listas',
            title: 'Listas',
            icon: 'fa-check',
            moduleId: 'campanias',
            typeId: 'donaciones',
            collectionId: '',
            params: {}
          },
          {
            id: 'canceladas',
            title: 'Canceladas',
            icon: 'fa-xmark',
            moduleId: 'campanias',
            typeId: 'donaciones',
            collectionId: '',
            params: {}
          }
        ],
        addDataFrom: []
      },
      {
        id: 'tickets',
        version: 1,
        icon: 'fa-person-circle-question',
        title: 'Tickets',
        moduleIds: ['sac'],
        defaultFilter: 'pendientes',
        actions: [],
        filters: [],
        addDataFrom: []
      }
    ]
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (itemId) => {
      const def = {
        id: '',
        icon: '',
        title: '',
        moduleIds: '',
        actions: [],
        filters: []
      }

      if (itemId < 0 || itemId === undefined) return def

      const index = state.items.findIndex(item => {
        return item.id === itemId
      })

      return index > -1
        ? state.items[index]
        : def
    }
  },
  actions: {
    generateDoc ({ state, dispatch, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        // payload = { model, prms, typeId }
        // model: {
        //   contacto: 'form',
        //   direccion: 'form',
        //   userScope: 'action'
        // }
        const { model, prms, typeId } = payload
        const index = state.items.findIndex(typ => typ.id === typeId)
        const { version } = state.items[index]
        const { name, uid } = rootGetters['app/user/data']

        const dataSources = {
          contacto: {
            form: { action: 'app/user/contacto/getFormContactoData', payload: '' },
            server: { action: 'app/user/contacto/getById', payload: { userId: prms.userId } }
          },
          donacion: {
            form: { action: 'campanias/donaciones/getFormDonacionData', payload: '' }
          },
          direccion: {
            form: { action: 'app/user/contacto/getFormDireccionData', payload: '' }
          },
          ticket_message: {
            form: { action: 'sac/tickets/getOnlyMessageData', payload: '' }
          }
        }

        // model: {
        //   userScope: 'action'
        // }

        const use = []
        Object.keys(model).forEach(keySource => {
          const estrategia = model[keySource]
          const { action, payload } = dataSources[keySource][estrategia]
          use.push({ action, payload })
        })

        const docBase = {
          type: {
            id: payload.typeId,
            v: version
          },
          roles: {
            created: { uid, name },
            updated: ''
          },
          tm: {
            created: Timestamp.fromDate(new Date()),
            updated: ''
          }
        }

        // separamos el key en array
        Promise.all(use.map(source => dispatch(source.action, source.payload, { root: true })))
          .then(dataArr => {
            dataArr.forEach(data => {
              Object.assign(docBase, data)
            })
            resolve(docBase)
          })
          .catch(e => {
            reject(e)
          })
      })
    },
    generateDocBaseForTypeId ({ state, dispatch, rootGetters }, typeId) {
      return new Promise((resolve, reject) => {
        // validaciones basica
        if (!typeId) reject(new Error(`No se puede generateDocBaseForTypeId con el ${typeId}`))
        const index = state.items.findIndex(typ => typ.id === typeId)
        if (index < 0) reject(new Error(`No se encontró el typo con id ${typeId}`))

        const { addDataFrom, version } = state.items[index]
        const { uid } = rootGetters['app/user/data']
        const userPerfil = rootGetters['app/user/perfil/byId'](uid)

        const docBase = {
          type: {
            id: typeId,
            v: version
          },
          roles: {
            created: { uid, name: userPerfil.name },
            updated: ''
          },
          tm: {
            created: Timestamp.fromDate(new Date()),
            updated: ''
          }
        }

        Promise.all(addDataFrom.map(source => dispatch(source.action, source.payload, { root: true })))
          .then(dataArr => {
            dataArr.forEach(data => {
              Object.assign(docBase, data)
            })
            resolve(docBase)
          })
          .catch(e => {
            reject(e)
          })
      })
    }
  },
  mutations: {},
  modules: {}
}

export default types
