<template>
  <v-app>
    <v-container class="d-flex fill-height justify-center blue-grey darken-2" fluid>
      <v-sheet class="rounded-lg pa-6" color="white" elevation="2">
        <!-- login sheet -->
        <div v-if="showLogin"
          class="d-inline-flex flex-column align-center justify-center"
        >
          <v-img max-height="120px" src="/imgs/metodos/cdlm.jpg"></v-img>
          <v-btn color="primary" class="pa-2" @click="signInWithGoogle">
            <v-icon class="ml-1" left>fa-brands fa-google</v-icon>
            Login with Google
          </v-btn>
        </div>
        <!-- user info -->
        <div v-else
          class="d-inline-flex flex-column text-center"
        >
          <!-- foto -->
          <v-avatar class="mx-auto mb-4" size="80px">
            <img :src="userData.photoURL" />
          </v-avatar>
          <!-- data -->
          <div class="font-weight-bold">{{userData.displayName}}</div>
          <div class="text--secondary">{{userData.email}}</div>
          <!-- signout -->
          <v-divider class="my-4"></v-divider>
          <div class="d-inline-flex flex-row justify-space-between">
            <v-btn :to="{ name: 'home' }" color="primary" class="align-self-center" outlined small>
              Home
            </v-btn>
            <v-btn :to="{ name: 'admin', params: { idAlcance: alcanceBase.id, tipoAlcance: alcanceBase.tipo } }" color="blue-grey" class="align-self-center ml-2" :disabled="!hasAdminAccess" outlined small>
              Admin
            </v-btn>
            <v-spacer></v-spacer>
            <v-divider class="mx-1" vertical></v-divider>
            <v-btn icon @click="signOut" color="secondary" class="align-self-center">
              <v-icon class="mr-1" right small>fa-arrow-right-from-bracket</v-icon>
            </v-btn>
          </div>
        </div>
      </v-sheet>
    </v-container>
</v-app>
</template>

<script>
export default {
  name: 'LoginFrame',
  computed: {
    alcanceBase () {
      return this.$store.getters['org/alcance/alcanceBase']
    },
    hasAdminAccess () {
      return this.$store.getters['app/user/permisos/hasAdminAccess']
    },
    showLogin () {
      return this.userData.uid === ''
    },
    userData () {
      return this.$store.getters['app/user/data']
    }
  },
  methods: {
    signInWithGoogle () {
      this.$store.dispatch('app/user/signInWithGoogle')
    },
    signOut () {
      this.$store.dispatch('app/user/signOut')
    }
  }
}
</script>
