//  <span class='pregReflex'>Reflexiona.</span>
// import { autb } from '../plugins/firebase'
import { getAuth, GoogleAuthProvider, signInWithPopup, signOut } from 'firebase/auth'
// import { db } from './firebase'

import contacto from './contacto/contacto'
import perfil from './perfil/perfil'
import permisos from './permisos/permisos'
import ubicaciones from './ubicaciones/ubicaciones'

const auth = getAuth()
const googleProv = new GoogleAuthProvider()

const user = {
  namespaced: true,
  state () {
    return {
      permissions: {
        adminAccess: true
      },
      tokens: {
        google: {}
      },
      user: {
        displayName: 'webmaster casa de la misericordia',
        email: 'webmaster.cdlm@gmail.com',
        phoneNumber: null,
        photoURL: 'https://lh3.googleusercontent.com/a-/AFdZucpAdigcI02qxnyJEpV36C1im5CaEiyutHsqQjRH=s96-c',
        uid: '0WXJ45e9JGOGkCmDBGYWllm8TM92'
      }
    }
  },
  actions: {
    signInWithGoogle ({ state }) {
      return new Promise((resolve, reject) => {
        signInWithPopup(auth, googleProv)
          .then(result => {
            const credential = GoogleAuthProvider.credentialFromResult(result)
            const token = credential.accessToken
            const user = result.user

            state.tokens.google = token
            resolve(user)
          })
          .catch(e => {
            const eMessage = e.message
            reject(eMessage)
            // const credential = GoogleAuthProvider.credentialFromError(e)
          })
      })
    },
    signOut () {
      signOut(auth)
    },
    stateChange ({ state }, user) {
      if (user) {
        Object.keys(user).forEach(key => {
          if (state.user[key] !== undefined) state.user[key] = user[key]
        })
      } else {
        Object.keys(state.user).forEach(key => {
          state.user[key] = ''
        })
      }
    }
  },
  getters: {
    data (state) {
      return state.user
    }
  },
  modules: {
    contacto,
    perfil,
    permisos,
    ubicaciones
  }
}

export default user
