// import { collection, addDoc } from 'firebase/firestore'
// import { db } from './firebase'
import helpers from './helpers/helpers'
import modules from './modules/modules'
import types from './types/types'
import user from './user/user'

const app = {
  namespaced: true,
  state: () => ({}),
  getters: {
    menuItems (state) {
      const data = []
      const adminHome = {
        id: 'admin_home',
        icon: 'fa-house-flag',
        title: 'Admin',
        to: { name: 'admin' }
      }
      data.push(adminHome)

      state.modules.items.forEach(mod => {
        // crea divisor y header
        // data.push({ divider: true, inset: false })
        // data.push({ header: mod.name })

        // busca cada colleccion de cada modulo
        mod.collections.forEach(collection => {
          collection.typeIds.forEach(typeId => {
            const index = state.types.items.findIndex(type => type.id === typeId)

            if (index > -1) {
              const typeInfo = state.types.items[index]

              const newMenuItem = {
                id: mod.id + '_' + typeInfo.id,
                icon: typeInfo.icon,
                title: typeInfo.title,
                to: {
                  // name: typeInfo.id,
                  name: 'docsWorkSpace',
                  params: { moduleId: mod.id, typeId: typeId, filterId: typeInfo.defaultFilter }
                  // TODO: no pasa la informacion a meta
                  // meta: { collection: collection.id } TODO:
                }
              }

              data.push(newMenuItem)
            }
          })
        })
      })
      return data
    },
    moduleTypes (state) {
      const data = []
      state.modules.items.forEach(mod => {
        const ob = { ...mod }
        const types = state.types.items.filter(type => {
          return type.moduleIds.findIndex(id => { return id === mod.id }) > -1
        })
        ob.types = types
        data.push(ob)
      })

      return data
    }
  },
  modules: { helpers, modules, types, user }
}

export default app
