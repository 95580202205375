// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'

const contacto = {
  namespaced: true,
  state: () => ({
    formContacto: {
      name: '',
      email: '',
      // telefono
      phoneCC: '', // CountryCode antes countryCode en formulario de datosContacto
      phone: '',
      phonePrefix: '',
      isWapp: null
    },
    formIdentidad: {
      countryDoc: '',
      docType: '',
      docIdent: ''
    },
    formDireccion: {
      address: '',
      address2: '',
      city: '',
      countryCode: ''
    },
    items: []
  }),
  actions: {
    getFormContactoData ({ state }, payload) {
      return new Promise(resolve => {
        resolve({ contacto: state.formContacto })
      })
    },
    getFormIdentidadData ({ state }, payload) {
      return new Promise(resolve => {
        resolve({ identidad: state.formIdentidad })
      })
    },
    getFormDireccionData ({ state }, payload) {
      return new Promise(resolve => {
        resolve({ direccion: state.formDireccion })
      })
    },
    setFormContacto ({ state }, payload) {
      Object.keys(payload).forEach(key => {
        if (state.formContacto[key] !== undefined) {
          state.formContacto[key] = payload[key]
        }
      })
    },
    setFormIdentidad ({ state }, payload) {
      Object.keys(payload).forEach(key => {
        if (state.formIdentidad[key] !== undefined) {
          state.formIdentidad[key] = payload[key]
        }
      })
    },
    setFormDireccion ({ state }, payload) {
      Object.keys(payload).forEach(key => {
        if (state.formDireccion[key] !== undefined) {
          state.formDireccion[key] = payload[key]
        }
      })
    }
  },
  getters: {
    byId: (state) => (itemId) => {
      // TODO default...
      const def = {}

      if (itemId < 0 || itemId === undefined) return def

      const index = state.items.findIndex(item => {
        return item.id === itemId
      })

      return index > -1
        ? state.items[index]
        : def
    },
    formContacto (state) {
      return state.formContacto
    },
    formIdentidad (state) {
      return state.formIdentidad
    },
    formDireccion (state) {
      return state.formDireccion
    },
    items (state) {
      return state.items
    }
  },
  modules: {}
}

export default contacto
