import Item from './item'

class Donacion extends Item {
  static entity = 'donaciones'

  static fields () {
    return {
      ...super.fields(),
      conceptId: this.string(''), // tipos primitivos, string, number y boolean
      currency: this.attr(''), // attr = atributo, reciben valor predeterminado
      detailId: this.attr(''), // nullable()
      detailTxt: this.attr(''), // this.uid(), acepta una funcion personalizada
      message: this.attr(''), // acepta atributos relacionales como this.hasMany()
      rolUser: this.attr(''),
      status: this.number(donacionStatus.onHold),
      value: this.number(0),
      typeId: this.attr('Donacion')
      // relacion con el recaudo
      // status
    }
  }

  static state () {
    return {
      type: {
        id: 'donacion',
        version: 1
      }
    }
  }

  static types () {
    return { Donaciones: Donacion }
  }

  static beforeSelect (items) {
    // console.log('beforeSelect')
    // console.log(this)
    // return donaciones
    // TODO
    // const pais = 'pais_co'
    // return donaciones.filter(donacion => {
    //   return donacion.scope === pais
    // })
  }

  // Elimina el registro de persistencia
  // @context info de la coleccion y subcoleccion
  static remove (context, itemId) {
    console.log(context, itemId)
  }

  static persist (context, itemData) {
    // c
  }
}

export const donacionStatus = {
  deleted: -1,
  eraser: 0,
  onHold: 1,
  inProgress: 2,
  ready: 3,
  cancelled: 4
}

export default Donacion
