import { lowerNormalize } from '@/plugins/helper'
// import { collection, addDoc } from 'firebase/firestore'
// import { db } from './firebase'

const alcance = {
  namespaced: true,
  state: () => ({
    ciudades: [
      { // Colombia
        id: 'alban-cund',
        enable: true,
        name: 'Albán - Cundinamarca',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'ciudad'
      },
      {
        id: 'barranquilla',
        enable: true,
        name: 'Barranquilla - Atlántico',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'bello-ant',
        enable: true,
        name: 'Bello - Antioquia',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'bogota',
        enable: true,
        name: 'Bogotá',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'bucaramanga',
        enable: true,
        name: 'Bucaramanga - Santander',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'cali',
        enable: true,
        name: 'Cali - Valle',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'cucuta',
        enable: true,
        name: 'Cúcuta - Norde de Santander',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'chia-cund',
        enable: true,
        name: 'Chía - Cundinamarca',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'duitama-boy',
        enable: true,
        name: 'Duitama - Boyacá',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'ibague',
        enable: true,
        name: 'Ibagué - Tolima',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'medellin',
        enable: true,
        name: 'Medellín - Antioquia',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      },
      {
        id: 'valle-san-jose-sant',
        enable: true,
        name: 'Valle de San José - Santander',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'ciudad'
      },
      {
        id: 'santa-barbara-ant',
        enable: true,
        name: 'Santa Bárbara - Antioquia',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'ciudad'
      },
      {
        id: 'san-pedro-ant',
        enable: true,
        name: 'San Pedro - Antioquia',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'ciudad'
      },
      {
        id: 'villanueva-sant',
        enable: true,
        name: 'Villanueva - Santander',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'ciudad'
      },
      { // Panamá
        id: 'panama',
        enable: true,
        name: 'Panamá - Panamá',
        codPais: 'pa',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'ciudad'
      }
    ],
    itemsAlcance: [
    ],
    orgs: [
      {
        id: 'cdlm',
        enable: true,
        name: 'Casa de la Misericordia',
        principal: true,
        tipo: 'org'
      }
    ],
    paises: [
      {
        id: 'aw',
        enable: true,
        cod: 'aw',
        name: 'Aruba',
        tipo: 'pais'
      },
      {
        id: 'bo',
        enable: true,
        cod: 'bo',
        name: 'Bolivia',
        tipo: 'pais'
      },
      {
        id: 'br',
        enable: true,
        cod: 'br',
        name: 'Brasil',
        tipo: 'pais'
      },
      {
        id: 'ca',
        enable: true,
        cod: 'ca',
        name: 'Canadá',
        tipo: 'pais'
      },
      {
        id: 'co',
        enable: true,
        cod: 'co',
        name: 'Colombia',
        tipo: 'pais'
      },
      {
        id: 'cr',
        enable: true,
        cod: 'cr',
        name: 'Costa Rica',
        tipo: 'pais'
      },
      {
        id: 'ec',
        enable: true,
        cod: 'ec',
        name: 'Ecuador',
        tipo: 'pais'
      },
      {
        id: 'es',
        enable: true,
        cod: 'es',
        name: 'España',
        tipo: 'pais'
      },
      {
        id: 'us',
        enable: true,
        cod: 'us',
        name: 'Estados Unidos',
        tipo: 'pais'
      },
      {
        id: 'hn',
        enable: true,
        cod: 'hn',
        name: 'Honduras',
        tipo: 'pais'
      },
      {
        id: 'ni',
        enable: true,
        cod: 'ni',
        name: 'Nicaragua',
        tipo: 'pais'
      },
      {
        id: 'pa',
        enable: true,
        cod: 'pa',
        name: 'Panamá',
        tipo: 'pais'
      },
      {
        id: 'py',
        enable: true,
        cod: 'py',
        name: 'Paraguay',
        tipo: 'pais'
      },
      {
        id: 'pe',
        enable: true,
        cod: 'pe',
        name: 'Perú',
        tipo: 'pais'
      },
      {
        id: 'pt',
        enable: true,
        cod: 'pt',
        name: 'Portugal',
        tipo: 'pais'
      },
      {
        id: 'pr',
        enable: true,
        cod: 'pr',
        name: 'Puerto Rico',
        tipo: 'pais'
      }
    ],
    sedes: [
      { // alban-cund
        id: 'alban-cund',
        enable: true,
        nombre: 'Santa Gema de Galgani',
        uso: 'Casa de retiros',
        ciudad: 'Albán - Cundinamarca',
        direccion: '',
        barrio: '',
        telefono: '',
        whatsappNum: '573105752412',
        whatsappTxt: '+57 310 575 2412',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // barranquilla
        id: 'barranquilla',
        enable: true,
        nombre: 'Hesed',
        uso: 'Comunidad',
        ciudad: 'Barranquilla - Atlántico',
        direccion: 'Calle 61 # 29-10 Apto. 1',
        barrio: '',
        telefono: '',
        whatsappNum: '573002692186',
        whatsappTxt: '+57 300 269 2186',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // bello-ant
        id: 'bello-ant',
        enable: true,
        nombre: 'María Auxiliador',
        uso: 'Comunidad',
        ciudad: 'Bello - Antioquia',
        direccion: 'Carrera 63 # 72 A 69',
        barrio: '',
        telefono: '',
        whatsappNum: '573102150116',
        whatsappTxt: '+57 310 215 0116',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // bogota-heroes
        id: 'bogota-heroes',
        enable: true,
        nombre: 'Cristo Te Sana',
        uso: 'Comunidad',
        ciudad: 'Bogotá',
        direccion: 'Carrera 22 # 77-15',
        barrio: 'San Felipe',
        telefono: '601 349 0662',
        whatsappNum: '',
        whatsappTxt: '',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // bogota-sur
        id: 'bogota-sur',
        enable: true,
        nombre: 'Jesús, Buen Samaritano',
        uso: 'Comunidad',
        ciudad: 'Bogotá',
        direccion: 'Carrera 74 Bis # 41 B 78',
        barrio: '',
        telefono: '',
        whatsappNum: '573144439788',
        whatsappTxt: '+57 314 443 9788',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // bucaramanga
        id: 'bucaramanga',
        enable: true,
        nombre: 'Jesús de Nazaret',
        uso: 'Comunidad',
        ciudad: 'Bucaramanga - Santander',
        direccion: 'Calle 54 # 29-11',
        barrio: '',
        telefono: '311 208 3963',
        whatsappNum: '573144439769',
        whatsappTxt: '+57 314 443 9769',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // cali
        id: 'cali',
        enable: true,
        nombre: 'Jesucristo Nuestra Esperanza',
        uso: 'Comunidad',
        ciudad: 'Cali - Valle',
        direccion: 'Calle 9 B # 29 A 70',
        barrio: '',
        telefono: '',
        whatsappNum: '573125847999',
        whatsappTxt: '+57 312 584 7999',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // cucuta
        id: 'cucuta',
        enable: true,
        nombre: 'María Auxiliadora',
        uso: 'Comunidad',
        ciudad: 'Cúcuta - Norde de Santander',
        direccion: 'Calle 14 # 2-37',
        barrio: '',
        telefono: '607 571 8398',
        whatsappNum: '',
        whatsappTxt: '',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // chia-cund
        id: 'chia-cund',
        enable: true,
        nombre: 'San Juan Bosco',
        uso: 'Comunidad',
        ciudad: 'Chía - Cundinamarca',
        direccion: 'Calle 10 # 4-20',
        barrio: '',
        telefono: '',
        whatsappNum: '573144439765',
        whatsappTxt: '+57 314 443 9765',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // duitama-boy
        id: 'duitama-boy',
        enable: true,
        nombre: 'Jesús, Divina Misericordia',
        uso: 'Comunidad y Casa de Acogida',
        ciudad: 'Duitama - Boyacá',
        direccion: 'Calle 13 # 37 A 68',
        barrio: '',
        telefono: '',
        whatsappNum: '573174046022',
        whatsappTxt: '+57 317 404 6022',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // ibague
        id: 'ibague',
        enable: true,
        nombre: 'María de Nazaret',
        uso: 'Comunidad',
        ciudad: 'Ibagué - Tolima',
        direccion: 'Carrera 2 # 9-26',
        barrio: 'Centro',
        telefono: '',
        whatsappNum: '573105752412',
        whatsappTxt: '+57 310 575 2412',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // medellin
        id: 'medellin',
        enable: true,
        nombre: 'Santa Faustina',
        uso: 'Comunidad',
        ciudad: 'Medellín - Antioquia',
        direccion: 'Carrera 50 # 62-24',
        barrio: 'Barrio Prado Centro',
        telefono: '',
        whatsappNum: '573102150116',
        whatsappTxt: '+57 215 0116',
        codPais: 'co',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // valle-san-jose-sant
        id: 'valle-san-jose-sant',
        enable: true,
        nombre: 'San Juan Pablo II',
        uso: 'Casa de acogida y desintoxicación',
        ciudad: 'Valle de San José - Santander',
        direccion: 'Vereda San José',
        barrio: '',
        telefono: '',
        whatsappNum: '573045764731',
        whatsappTxt: '+57 304 576 4731',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // santa-barbara-ant
        id: 'santa-barbara-ant',
        enable: true,
        nombre: 'Santa Bárbara',
        uso: 'Casa de acompañamiento',
        ciudad: 'Santa Bárbara - Antioquia',
        direccion: 'Vereda Palocoposo',
        barrio: '',
        telefono: '',
        whatsappNum: '573152465500',
        whatsappTxt: '+57 315 246 5500',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // san-pedro-ant
        id: 'san-pedro-ant',
        enable: true,
        nombre: 'Señor de los Milagros',
        uso: 'Comunidad',
        ciudad: 'San Pedro - Antioquia',
        direccion: 'Calle 48 # 51 B 08',
        barrio: 'Belén',
        telefono: '',
        whatsappNum: '573204433745',
        whatsappTxt: '+57 320 443 3745',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // villanueva-sant
        id: 'villanueva-sant',
        enable: true,
        nombre: 'María Reina de la Paz',
        uso: 'Comunidad',
        ciudad: 'Villanueva - Santander',
        direccion: 'Carrera 10 # 14-06',
        barrio: '',
        telefono: '',
        whatsappNum: '573046377852',
        whatsappTxt: '+57 304 637 7852',
        codPais: 'co',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // panama san francisco
        id: 'panama',
        enable: true,
        nombre: 'San Francisco de Asís',
        uso: 'Comunidad',
        ciudad: 'Arraiján - Panamá',
        direccion: 'Carretera Panamericana frente a Malambo',
        barrio: '',
        telefono: '247 4027',
        whatsappNum: '50766568748',
        whatsappTxt: '+507 6656-8748',
        codPais: 'pa',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // panama dei gloriam
        id: 'panama-villa-del-retiro',
        enable: true,
        nombre: 'Dei Gloriam',
        uso: 'Casa de retiros',
        ciudad: 'Arraiján - Panamá',
        direccion: 'Vía Chapala, Nuevo Arraiján. Entrada de la Barriada Hiltop',
        barrio: 'Barrio Hiltop',
        telefono: '',
        whatsappNum: '50767398193',
        whatsappTxt: '+507 6739-8193',
        codPais: 'pa',
        servicios: { recaudoDomicilio: 1 },
        tipo: 'sede'
      },
      { // panama distrito chepo
        id: 'panama-isla-de-la-misericordia',
        enable: true,
        nombre: 'Isla de la Misericordia',
        uso: '',
        ciudad: 'Bayano - Panamá',
        direccion: 'Represa de Bayano',
        barrio: '',
        telefono: '',
        whatsappNum: '50766298129',
        whatsappTxt: '+507 6629-8129',
        codPais: 'pa',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // brasil
        id: 'rio-de-janeiro',
        enable: true,
        nombre: 'Nossa Senhora da Aparecida',
        uso: 'Comunidad',
        ciudad: 'Rio de Janeiro',
        direccion: '',
        barrio: '',
        telefono: '',
        whatsappNum: '5521992723457',
        whatsappTxt: '+55 (21) 99 272 3457',
        codPais: 'br',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // costa rica
        id: 'morazan-costa-rica',
        enable: true,
        nombre: 'San Isidro del General',
        uso: 'Comunidad',
        ciudad: 'Morazán',
        direccion: 'Contiguo al Santuario Diocesano a Jesús de la Divina Misericordia.',
        barrio: '',
        telefono: '2771-3595',
        whatsappNum: '50686716468',
        whatsappTxt: '+506 8671-6468',
        codPais: 'cr',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // ecuador
        id: 'guayaquil-ec',
        enable: true,
        nombre: 'San Juan de Dios',
        uso: 'Comunidad',
        ciudad: 'Guayaquil',
        direccion: '',
        barrio: '',
        telefono: '9 9995 4446',
        whatsappNum: '',
        whatsappTxt: '',
        codPais: 'ec',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // eeuu - atlanta
        id: 'atlanta-us',
        enable: false,
        nombre: '',
        uso: '',
        ciudad: 'Atlanta - Georgia',
        direccion: 'P.O.BOX 83327 Conyers G.A. 30013',
        barrio: '',
        telefono: '770 356 3054',
        whatsappNum: '',
        whatsappTxt: '',
        codPais: 'us',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      },
      { // eeuu - houston
        id: 'houston-tx-us',
        enable: false,
        nombre: '',
        uso: '',
        ciudad: 'Houston TX',
        direccion: '',
        barrio: '',
        telefono: '832 - 846 7554',
        whatsappNum: '18327481058',
        whatsappTxt: '+1 (832) 748 1058',
        codPais: 'us',
        servicios: { recaudoDomicilio: 0 },
        tipo: 'sede'
      }
    ]
  }),
  getters: {
    alcanceBase (state) {
      // TODO
      return state.orgs[0]
    },
    alcanceSelected (state, getters, rootState, rootGetters) {
      const { idAlcance, tipoAlcance } = rootGetters._params
      const def = getters.alcanceBase

      const sourceTypes = {
        ciudad: 'ciudades',
        org: 'orgs',
        pais: 'paises',
        sede: 'sedes'
      }

      const source = sourceTypes[tipoAlcance]

      if (!idAlcance || !tipoAlcance || !source) return def
      if (!state[source]) return def

      const index = state[source].findIndex(item => {
        return item.id === idAlcance
      })

      return index > -1
        ? state[source][index]
        : def
    },
    ciudades (state) {
      return state.ciudades.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    },
    ciudadesPorPais: (state) => (countryCod) => {
      if (countryCod === '') return []

      return state.ciudades.filter(ciudad => {
        return ciudad.codPais === countryCod
      })
    },
    ciudadesPorPaisServicio: (state) => (filter) => {
      if (filter.countryCode === undefined || filter.servicio === undefined) return []

      return state.ciudades.filter(ciudad => {
        return ciudad.codPais === filter.countryCode &&
          ciudad.servicios[filter.servicio] === 1
      })
    },
    orgs (state) {
      return state.orgs
    },
    paises (state) {
      return state.paises.sort((a, b) => {
        if (a.name < b.name) return -1
        if (a.name > b.name) return 1
        return 0
      })
    },
    paisByCod: (state) => (cod) => {
      const index = state.paises.findIndex(pais => {
        return pais.cod === cod
      })
      return index > -1
        ? state.paises[index]
        : {
            cod: 'mundo',
            name: 'todo el mundo.'
          }
    },
    paisSelected (state, getters, rootState, rootGetters) {
      const { codPais } = rootGetters._params
      const index = state.paises.findIndex(pais => {
        return pais.cod === codPais
      })
      return index > -1
        ? state.paises[index]
        : {
            cod: 'mundo',
            name: 'todo el mundo'
          }
    },
    sedes (state) {
      return state.sedes.sort((a, b) => {
        if (a.nombre < b.nombre) return -1
        if (a.nombre > b.nombre) return 1
        return 0
      })
    }
  },
  actions: {
    createItemsAlcance ({ getters, rootGetters }) {
      return new Promise((resolve, reject) => {
        const data = []

        // organizacion
        data.push({ header: 'Organización' })
        getters.orgs.forEach(org => {
          if (org.enable) {
            data.push({
              id: `org_${org.id}`,
              name: org.name,
              stxt: lowerNormalize(org.name),
              tipo: 'org'
            })
          }
        })

        // Paises
        data.push({ divider: true, inset: false })
        data.push({ header: 'Países' })
        getters.paises.forEach(pais => {
          if (pais.enable) {
            data.push({
              id: `pais_${pais.id}`,
              name: pais.name,
              stxt: lowerNormalize(`pais ${pais.name}`),
              tipo: 'pais'
            })
          }
        })

        // Ciudades
        data.push({ divider: true, inset: false })
        data.push({ header: 'Ciudades' })
        getters.ciudades.forEach(ciudad => {
          if (ciudad.enable) {
            data.push({
              id: `ciudad_${ciudad.id}`,
              name: ciudad.name,
              stxt: lowerNormalize(`ciudad ${ciudad.name} ${ciudad.codPais}`),
              tipo: 'ciudad'
            })
          }
        })

        // Sedes
        data.push({ divider: true, inset: false })
        data.push({ header: 'Sedes' })
        getters.sedes.forEach(sede => {
          if (sede.enable) {
            data.push({
              id: `ciudad_${sede.id}`,
              name: sede.nombre,
              stxt: lowerNormalize(`sede ${sede.nombre} ${sede.ciudad}`),
              tipo: 'ciudad'
            })
          }
        })

        resolve(data)
      })
    }
  }
}

export default alcance
