import { addDoc, collection, Timestamp } from 'firebase/firestore'
import { db } from '@/plugins/fbase'

const tickets = {
  namespaced: true,
  state: () => ({
    _params: {
      collectionName: 'sac_tickets',
      typeId: 'tickets',
      typeVersion: 1
    },
    form: {
      area: '',
      message: '',
      priority: 0,
      status: 1 // 0 borrador, 1 pendiente, 2 abierto, 3 cerrados...
    },
    items: []
  }),
  actions: {
    clienteSolicitaAyudaPorWhatsapp ({ state, dispatch }) {
      return new Promise((resolve, reject) => {
        const { collectionName } = state._params
        const { current } = this.$router.history

        dispatch('generarNuevoTicket')
          .then(newTicket => {
            newTicket.action = {}
            newTicket.action.id = 'clienteSolicitaAyudaPorWhatsapp'
            newTicket.action.route = { name: current.name, params: current.params }

            addDoc(collection(db, collectionName), newTicket)
              .then(doc => {
                const { name, phone, phonePrefix } = newTicket.contacto

                const parte1 = name === ''
                  ? 'Hola, me gustaría apoyar la comunidad con una donación.'
                  : `Hola, me llamo ${name} quiero apoyar la comunidad con una donación.`

                const parte2 = phone === ''
                  ? ''
                  : ` Mi teléfono es ${phonePrefix} ${phone}`

                const msg = parte1 + parte2

                dispatch('sac/openWhatsapp', { msg }, { root: true })
                resolve()
              })
              .catch(e => {
                reject(e)
              })
          })
      })
    },
    clienteSolicitaInstrPorWhastapp ({ state, dispatch, rootGetters }) {
      return new Promise((resolve, reject) => {
        const { collectionName } = state._params
        const { current } = this.$router.history

        dispatch('generarNuevoTicket')
          .then(newTicket => {
            newTicket.action = {}
            newTicket.action.id = 'clienteSolicitaInstrPorWhastapp'
            newTicket.action.route = { name: current.name, params: current.params }

            addDoc(collection(db, collectionName), newTicket)
              .then(doc => {
                const { name, phone, phonePrefix } = newTicket.contacto
                const metodoInfo = rootGetters['recaudos/metodos/byId'](current.params.metodoId)

                const parte1 = name === ''
                  ? 'Hola, me gustaría apoyar la comunidad con una donación.'
                  : `Hola, me llamo ${name} y quiero apoyar la comunidad con una donación.`

                const parte2 = phone === ''
                  ? ''
                  : ` Mi teléfono es ${phonePrefix} ${phone}.`

                const parte3 = ` Me pueden enviar instrucciones para saber cómo ${metodoInfo.instr}`

                const msg = parte1 + parte2 + parte3

                dispatch('sac/openWhatsapp', { msg }, { root: true })
                resolve()
              })
              .catch(e => {
                reject(e)
              })
          })
      })
    },
    generarNuevoTicket ({ state, dispatch, rootGetters }, payload) {
      return new Promise((resolve, reject) => {
        const { typeId, typeVersion } = state._params
        const { uid } = rootGetters['app/user/data']
        const userPerfil = rootGetters['app/user/perfil/byId'](uid)
        const formContacto = rootGetters['app/user/contacto/formContacto']

        const docBase = {
          type: {
            id: typeId,
            v: typeVersion
          },
          roles: {
            created: { uid, name: userPerfil.name },
            updated: ''
          },
          tm: {
            created: Timestamp.fromDate(new Date()),
            updated: ''
          },
          contacto: { ...formContacto }
        }

        // informacion del ticket
        docBase.ticket = { ...state.form }

        // add roles
        docBase.roles.sacUser = { uid: '', name: '' }

        // add times
        docBase.tm.firsA = null
        docBase.tm.closed = null

        resolve(docBase)
      })
    },
    getOnlyMessageData ({ state }, payload) {
      return new Promise(resolve => {
        resolve({ ticket: { message: state.form.message } })
      })
    }
  },
  getters: {
    byId: (state) => (itemId) => {
      // TODO default...
      const def = {}

      if (itemId < 0 || itemId === undefined) return def

      const index = state.items.findIndex(item => {
        return item.id === itemId
      })

      return index > -1
        ? state.items[index]
        : def
    },
    items (state) {
      return state.items
    },
    form (state) {
      return state.form
    },
    statusAbiertos (state) {
      return []
    },
    statusCerrados (state) {
      return []
    },
    statusPendiente (state) {
      return []
    }
  },
  mutations: {
    setForm (state, payload) {
      Object.keys(payload).forEach(key => {
        if (state.form[key] !== undefined) {
          state.form[key] = payload[key]
        }
      })
    }
  }
}

export default tickets
