// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'

const fotos = {
  namespaced: true,
  state: () => ({
    items: [
      {
        id: 1,
        src: '/imgs/camp-22/comunidad/comunidad-01.jpg',
        caption: 'Lorem ipsum caption text 01'
      },
      {
        id: 2,
        src: '/imgs/camp-22/comunidad/comunidad-02.jpg',
        caption: 'Lorem ipsum caption text 02'
      },
      {
        id: 3,
        src: '/imgs/camp-22/comunidad/comunidad-03.jpg',
        caption: 'Lorem ipsum caption text 03'
      },
      {
        id: 4,
        src: '/imgs/camp-22/comunidad/comunidad-04.jpg',
        caption: 'Lorem ipsum caption text 04'
      },
      {
        id: 5,
        src: '/imgs/camp-22/comunidad/comunidad-05.jpg',
        caption: 'Lorem ipsum caption text 05'
      },
      {
        id: 6,
        src: '/imgs/camp-22/comunidad/comunidad-06.jpg',
        caption: 'Lorem ipsum caption text 06'
      },
      {
        id: 7,
        src: '/imgs/camp-22/comunidad/comunidad-07.jpg',
        caption: 'Lorem ipsum caption text 07'
      },
      {
        id: 8,
        src: '/imgs/camp-22/comunidad/comunidad-08.jpg',
        caption: 'Lorem ipsum caption text 08'
      },
      {
        id: 9,
        src: '/imgs/camp-22/comunidad/comunidad-09.jpg',
        caption: 'Lorem ipsum caption text 09'
      }
    ]
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (id) => {
      const index = state.items.findIndex(item => {
        return item.id === id
      })
      return index > -1
        ? state.items[index]
        : {} // TODO: default
    }
  },
  actions: {},
  mutations: {},
  modules: {}
}

export default fotos
