import Vue from 'vue'
import UiPwa from './ui/UiPwa.vue'
import Plugins from './plugins/_index'
// import { getAuth, onAuthStateChanged } from 'firebase/auth'

Vue.config.productionTip = true

new Vue({
  router: Plugins.router,
  store: Plugins.store,
  vuetify: Plugins.vuetify,
  created () {
    // agrega $infoo, $router $voux $fb $local a store
    this.$store.$router = Plugins.router
    this.$store.$local = Plugins.localData

    // agrega $grefs a this / componente vue
    // this.$grefs = {}
    // this.$grefs.installPrompt = null

    // el usuario hace login o logout
    // const auth = getAuth()
    // onAuthStateChanged(auth, user => {
    //   this.$store.dispatch('app/user/stateChange', user)
    // })

    //  cargar app
    this.$store.dispatch('app/helpers/getClientLocation')
  },
  render: h => h(UiPwa)
}).$mount('#app')
