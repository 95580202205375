import Vue from 'vue'
import VueRouter from 'vue-router'
import store from './store'
import HomeFrame from '../ui/__/HomeFrame'
import LoginFrame from '../ui/__/LoginFrame'

Vue.use(VueRouter)

// const adminStore = () => import(/* webpackChunkName: "adminStore" */ '../store/admin/admin.js')

// const importAdminStore = () => {
//   return adminStore().then((admin) => {
//     store.registerModule('admin', admin)
//   })
// }

const adminUse = false

const adminRoutes = [
  // login
  {
    path: '/login',
    name: 'login',
    component: LoginFrame,
    meta: { isPublic: true }
  },

  // Admin - - - - - - - - - - - - - - -
  {
    path: '/admin',
    components: { default: () => import(/* webpackChunkName: "adminPages" */ '../ui/__/AdminFrame.vue') },
    props: { default: true },
    children: [
      // DocsWorkSpace Action
      {
        path: ':moduleId/:typeId/action/:actionId/:itemId',
        sensitive: true,
        name: 'DocsActionMain',
        components: { main: () => import(/* webpackChunkName: "adminPages" */ '../ui/admin/mains/DocsActionMain.vue') },
        props: { default: true, main: true }
      },
      // DocsWorkSpace
      {
        path: ':moduleId/:typeId/items/:filterId?',
        sensitive: true,
        name: 'docsWorkSpace',
        components: { main: () => import(/* webpackChunkName: "adminPages" */ '../ui/admin/mains/DocsWorkSpaceMain.vue') },
        props: { default: true, main: true }
      },
      // Dashboard
      {
        path: '',
        name: 'admin',
        components: { main: () => import(/* webpackChunkName: "adminPages" */ '../ui/admin/mains/DashBoardMain.vue') },
        props: { default: true, main: true }
      }
    ]
    // beforeEnter: (to, from, next) => {
    //   const userHasAdminAccess = store.getters['app/user/permisos/hasAdminAccess']
    //   if (!store.state.admin) {
    //     importAdminStore()
    //       .then(() => next())
    //       .catch(e => console.error('No se pudo importar admin store', e))
    //   } else if (!userHasAdminAccess) {
    //     next({ name: 'login' })
    //   } else { next() }
    // }
  }
]

const homeRoutes = [
  // home
  {
    path: '/',
    component: HomeFrame,
    children: [
      // Campaña 2022
      // Metas
      {
        path: ':landingId/meta/:id',
        name: 'meta',
        components: {
          dialogSubView: () => import(/* webpackChunkName: "lazzySubViews" */ '../ui/types/meta/MetaFull.vue')
        },
        meta: { isPublic: true, showDialog: true }
      },
      // Testimonios
      {
        path: ':landingId/testimonio/:id',
        name: 'testimonio',
        components: {
          dialogSubView: () => import(/* webpackChunkName: "lazzySubViews" */ '../ui/types/testimonio/TestimonioFull.vue')
        },
        meta: { isPublic: true, showDialog: true }
      },
      // Videos
      {
        path: ':landingId/video/:id',
        name: 'video',
        components: {
          dialogSubView: () => import(/* webpackChunkName: "lazzySubViews" */ '../ui/types/video/VideoFull.vue')
        },
        meta: { isPublic: true, showDialog: true }
      },

      // - - - - - - - - - - - - - - -
      // Metodos
      {
        path: 'donar-en/:codPais',
        name: 'metodo-list-pais',
        components: {
          bottomSheets: () => import(/* webpackChunkName: "lazzySubViews" */ '../ui/types/metodo/MetodoList.vue')
        },
        meta: { isPublic: true, showBottomSheet: true }
      },
      {
        path: 'donar-en/:codPais/por/:canalId',
        name: 'metodo-list-pais-canal',
        components: {
          bottomSheets: () => import(/* webpackChunkName: "lazzySubViews" */ '../ui/types/metodo/MetodoList.vue')
        },
        meta: { isPublic: true, showBottomSheet: true }
      },
      {
        path: 'donar-en/:codPais/mediante/:metodoId',
        name: 'metodo-full',
        components: {
          bottomSheets: () => import(/* webpackChunkName: "lazzySubViews" */ '../ui/types/metodo/MetodoFull.vue')
        },
        meta: { isPublic: true, showBottomSheet: true, ignoreThisUrl: true, transition: 'right' }
      },

      // - - - - - - - - - - - - - - -
      // Page
      {
        path: 'pagina/:pageId',
        name: 'page',
        components: {
          bottomSheets: () => import(/* webpackChunkName: "lazzyPages" */ '../ui/types/page/PageFull.vue')
        },
        props: { default: true, bottomSheets: true },
        meta: { isPublic: true, showBottomSheet: true }
      },

      // - - - - - - - - - - - - - - -
      // home
      {
        path: '',
        name: 'home',
        meta: { isPublic: true }
      }
    ]
  }
]

const redirecRoute = [
  {
    path: '/*',
    name: '404',
    redirect: { name: 'home' }
  }
]

const router = new VueRouter({
  mode: 'history',
  base: process.env.BASE_URL,
  routes: adminUse
    ? [].concat(adminRoutes, homeRoutes, redirecRoute)
    : [].concat(homeRoutes, redirecRoute)
})

const isAuth = () => {
  // TODO: store
  return true
}

const hasAccess = async (to) => {
  // TODO: store
  // if no tiene acceso, dispara mensaje y pantalla de solicita acceso.
  requireAccess(to)
  return true
}

const requireAccess = (to) => {
  // TODO: almacenar solicitud sin acceso
}

router.afterEach((to, from) => {
  if (to.params.canalId !== undefined) {
    store.commit('setParams', { canalId: to.params.canalId })
  }
  if (to.params.codPais !== undefined) {
    store.commit('setParams', { codPais: to.params.codPais })
  }
  // if (to.params.idAlcance !== undefined) {
  //   store.commit('setParams', { idAlcance: to.params.idAlcance })
  // }
  // if (to.params.tipoAlcance !== undefined) {
  //   store.commit('setParams', { tipoAlcance: to.params.tipoAlcance })
  // }
})

router.beforeEach((to, from, next) => {
  if (to.meta.isPublic) next()
  else if (!isAuth()) next({ name: 'login' })
  else if (!hasAccess(to)) return false
  // else if (!hasAccess(to)) next({ name: 'requireAccess' })
  else next()
})

export default router
