// import { collection, addDoc } from 'firebase/firestore'
// import { db } from '@/plugins/firebase'
// const collectionName = 'campanias_metas'

const testimonios = {
  namespaced: true,
  state: () => ({
    items: [
      {
        id: 'mons-juan-vicente-cordoba',
        src: '/imgs/testimonios/mons-juan-vicente-cordoba.jpg',
        caption: 'Mons. Juan Vicente Córdoba',
        cargo: 'Obispo Diócesis de Fontibón',
        text: 'Te comparto la invitación que nos hizo Mons. Juan Vicente. "Misericordia Quiero".',
        videoId: 'mgPWf74NgkE',
        tag: {
          obispo: true,
          co: true
        }
      },
      {
        id: 'carolina-quijada',
        src: '/imgs/testimonios/carolina-quijada.jpg',
        caption: 'Carolina Quijada',
        cargo: 'Misionera',
        text: 'Te comparto el testimonio de Carolina Quijada, nuestra misionera en Panamá. "Misericordia Quiero".',
        videoId: 'yf9Vbjkor2U',
        tag: {}
      },
      {
        id: 'sebastian-lopez',
        src: '/imgs/testimonios/sebastian-lopez.jpg',
        caption: 'Sebastian López',
        cargo: 'Misionero',
        text: 'Te comparto el testimonio de Sebastian López, nuestro misionero. "Misericordia Quiero".',
        videoId: 'zs_odD6EOvE',
        tag: {}
      },
      {
        id: 'alexandra-vargas',
        src: '/imgs/testimonios/alexandra-vargas.jpg',
        caption: 'Alexandra Vargas',
        cargo: 'Misionera',
        text: 'Te comparto el testimonio de Alexandra Vargas, nuestro misionera. "Misericordia Quiero".',
        videoId: 'DuErgtrB6Ls',
        tag: {}
      },
      {
        id: 'alexander-perez',
        src: '/imgs/testimonios/alexander-perez.jpg',
        caption: 'Alexander Pérez',
        cargo: 'Funcionario',
        text: 'Te comparto el testimonio de Alexander Pérez, nuestro mensajero y misionero en Bogotá. "Misericordia Quiero".',
        videoId: 'TuaXXoMUFbc',
        tag: {}
      },
      {
        id: 'rosario-cortez',
        src: '/imgs/testimonios/rosario-cortez.jpg',
        caption: 'Rosario Cortez',
        cargo: 'Coordinadora',
        text: 'Te comparto el testimonio de Rosario Cortez, nuestro  hizo coordinadora. "Misericordia Quiero".',
        videoId: '7eJHCkBnB6k',
        tag: {}
      },
      {
        id: 'amanda-cardona',
        src: '/imgs/testimonios/amanda-cardona.jpg',
        caption: 'Amanda Cardona',
        cargo: 'Socia',
        text: 'Te comparto el testimonio de Amanda Cardona, una de nuestras benefactoras en Bogotá. "Misericordia Quiero".',
        videoId: 'oKqbEyrvAbE',
        tag: {}
      }
    ]
  }),
  getters: {
    items (state) {
      return state.items
    },
    byId: (state) => (id) => {
      const index = state.items.findIndex(item => {
        return item.id === id
      })
      return index > -1
        ? state.items[index]
        : {} // TODO: default
    }
  },
  actions: {},
  mutations: {},
  modules: {}
}

export default testimonios
