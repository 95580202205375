<template>
  <v-app-bar app dark color="#E53935" hide-on-scroll>
    <!-- image -->
    <template v-slot:img="{ props }">
      <v-img
        v-bind="props"
        src="/imgs/camp-22/mq-bg-paralax-v1.jpg"
        gradient="to top right, rgba(230,58,56,.5), rgba(230,58,56,.1)"
      ></v-img>
    </template>

    <!-- isotipo -->
    <!-- <v-container>
      <div class="d-flex flex-inline justify-center">
        <v-img src="/imgs/camp-22/imagotipo-mq-v1.png" max-width="150px"></v-img>
      </div>
    </v-container> -->

    <!-- title and menu -->
    <v-container class="maxc pa-0 d-inline-flex align-center justify-space-between">
      <div class="mx-auto d-inline-flex align-center">
        <v-img src="/imgs/icons/isotipo-mq.svg" max-height="40px" max-width="40px" contain></v-img>
        <h2 class="d-none d-sm-flex ml-3">Misericordia Quiero</h2>
      </div>
      <v-spacer></v-spacer>
      <div class="mx-auto">
        <!-- donar -->
        <v-btn :to="{ name: 'metodo-list-pais', params: { codPais: paisSelected.cod } }"
          text
        >
          Donar
        </v-btn>
        <!-- menu paises -->
        <v-menu offset-y left>
          <template v-slot:activator="{ on, attrs }">
            <v-btn v-on="on" v-bind="attrs" icon>
              <v-icon color="white" v-if="paisSelected.cod === 'mundo'">fa-earth-americas</v-icon>
              <flag v-else :iso="paisSelected.cod" style="font-size:22px" class="rounded-circle" />
            </v-btn>
          </template>

          <v-list>
            <v-list-item-group active-class="primary--text">
              <!-- en otros paises -->
              <v-list-item @click="seleccionarPais('otros-paises')" v-ripple="{ class: 'primary--text' }">
                <v-list-item-content>
                  <v-list-item-title>
                    En otros países
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-icon color="primary lighten-3">fa-earth-americas</v-icon>
                </v-list-item-action>
              </v-list-item>
              <v-divider></v-divider>
              <!-- listado paises -->
              <v-list-item v-for="pais in paises" :key="pais.cod"
                @click="seleccionarPais(pais.cod)"
                v-ripple="{ class: 'primary--text' }"
              >
                <v-list-item-content>
                  <v-list-item-title>
                    en {{pais.name}}
                  </v-list-item-title>
                </v-list-item-content>
                <v-list-item-action>
                  <v-avatar color="primary" size="24">
                    <flag :iso="pais.cod" style="font-size:24px" />
                  </v-avatar>
                </v-list-item-action>
              </v-list-item>
            </v-list-item-group>
          </v-list>
        </v-menu>
        <!-- menu -->
        <!-- <v-btn class="ml-1" icon @click.stop="showMenu">
          <v-icon>fa-bars</v-icon>
        </v-btn> -->
      </div>
    </v-container>
  </v-app-bar>
</template>

<script>
import Vuetify from '../../../plugins/vuetify'

export default {
  name: 'MqAppBar',
  props: {
    banners: {
      type: Object,
      default: function () {
        return {
          vertical: {
            xs: '34vh',
            sm: '36vh',
            md: '38vh',
            lg: '40vh',
            xl: '42vh'
          },
          horizontal: {
            xs: '20vh',
            sm: '75vh',
            md: '34vh',
            lg: '38vh',
            xl: '42vh'
          }
        }
      }
    }
  },
  computed: {
    altoBanner () {
      const name = Vuetify.framework.breakpoint.name
      return this.isVertical
        ? this.banners.vertical[name]
        : this.banners.horizontal[name]
    },
    isVertical () {
      return Vuetify.framework.breakpoint.height > Vuetify.framework.breakpoint.width
    },
    paises () {
      return this.$store.getters['org/alcance/paises']
    },
    paisSelected () {
      return this.$store.getters['org/alcance/paisSelected']
    }
  },
  methods: {
    seleccionarPais (value) {
      this.$store.commit('setParams', { codPais: value })
    },
    showMenu () {
      this.$emit('showmenu')
    }
  }
}
</script>
